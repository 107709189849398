import * as WebBrowser from "expo-web-browser";
import { Linking } from "react-native";
import { SET_DROP_DOWN_ALERT_ERROR } from "../actions/types";
import { DEEPLINK_URL_SCHEME, PWA_DEEPLINK_APP_URL } from "../config/constants";
import env from "../config/env";
import { t } from "../services/i18n";
import { COLOR2, isWeb, WHITE_COLOR } from "../styles/commonStyles";

const DEEPLINK_DEV_URL = env.deepLinkUrl || "exp://127.0.0.1:19000/--/";

export const getRedirectLink = url => {
  let index = -1;
  if (__DEV__ && (index = url?.indexOf("19006")) > 0) {
    return url.substring(index + 5);
  } else if (url?.startsWith(DEEPLINK_DEV_URL)) {
    return url.substring(DEEPLINK_DEV_URL.length);
  } else if (url?.startsWith(PWA_DEEPLINK_APP_URL)) {
    return url.substring(PWA_DEEPLINK_APP_URL.length);
  } else if (url?.startsWith(DEEPLINK_URL_SCHEME)) {
    return url.substring(DEEPLINK_URL_SCHEME.length);
  } else {
    return null;
  }
};

export async function openBrowser(dispatch, url) {
  try {
    if (isWeb) {
      return await window.open(url, "_blank");
    }
    return await WebBrowser.openBrowserAsync(url, {
      toolbarColor: COLOR2,
      controlsColor: WHITE_COLOR
    });
  } catch (err) {
    console.error("An error occurred", err);
    dispatch({
      type: SET_DROP_DOWN_ALERT_ERROR,
      error: t("alert:urlerror")
    });
  }
}

export async function openURL(dispatch, url) {
  try {
    if (isWeb && url.startsWith("http")) {
      return await window.open(url, "_blank");
    } else {
      const supported = await Linking.canOpenURL(url);
      if (supported) {
        return await Linking.openURL(url);
      } else {
        dispatch({
          type: SET_DROP_DOWN_ALERT_ERROR,
          error: t("alert:urlnotsupported")
        });
      }
    }
  } catch (err) {
    console.error("An error occurred", err);
    dispatch({
      type: SET_DROP_DOWN_ALERT_ERROR,
      error: t("alert:urlerror")
    });
  }
}

export async function postOnFacebook(uri, quote) {
  let facebookParameters = [];
  facebookParameters.push("u=" + encodeURI(uri));
  facebookParameters.push("quote=" + encodeURI(quote));

  const url =
    "https://www.facebook.com/sharer/sharer.php?" +
    facebookParameters.join("&");
  return await window.open(url, "_blank");
}

export async function postOnInstagram(uri, quote) {
  let instagramParameters = [];
  instagramParameters.push("url=" + encodeURI(uri));
  instagramParameters.push("quote=" + encodeURI(quote));

  const url = "https://www.instagram.com/?" + instagramParameters.join("&");
  return await window.open(url, "_blank");
}

export async function postOnLinkedIn(uri) {
  let linkedinParameters = [];
  linkedinParameters.push("url=" + encodeURI(uri));

  const url =
    "https://www.linkedin.com/shareArticle?mini=true&" +
    linkedinParameters.join("&");
  return await window.open(url, "_blank");
}

// Max 280 characters for a tweet
export async function postOnTwitter(uri, text) {
  let twitterParameters = [];
  twitterParameters.push("url=" + encodeURI(uri));
  if (text.length + uri.length > 280) {
    text = text.substring(0, 280 - uri.length) + "... @digihapi";
  }
  twitterParameters.push("text=" + encodeURI(text));

  const url = "https://twitter.com/intent/tweet?" + twitterParameters.join("&");
  return await window.open(url, "_blank");
}

export async function postOnWhatsApp(uri, text) {
  let whatsappParameters = [];
  whatsappParameters.push("text=" + encodeURI(text + " " + uri));

  const url = "whatsapp://send?" + whatsappParameters.join("&");
  return await window.open(url, "_blank");
}
