import {
  FontAwesome,
  Fontisto,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/core";
import { useHeaderHeight } from "@react-navigation/elements";
import * as Clipboard from "expo-clipboard";
import Constants from "expo-constants";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  View
} from "react-native";
import { Button, Icon, SocialIcon } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetAdDetails,
  fetchGetAdsCategories,
  fetchUpdateAdMapPhotoUri
} from "../actions/ad";
import {
  fetchGetGoodDealsTemplates,
  fetchGetTemplateDates,
  fetchGetTemplateTimes
} from "../actions/template";
import {
  CLEAR_AD,
  SET_PUBLISH_GOODDEAL_TEMPLATE,
  SHARE,
  TYPE_GOODDEAL_ACTIVITY
} from "../actions/types";
import ActionButton from "../components/actionButton/ActionButton";
import { getActivities } from "../selectors";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  COLOR3,
  COLOR5,
  isNative,
  isSafari,
  isWeb,
  ORANGE_COLOR,
  SCREEN_HEIGHT,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import {
  openBrowser,
  openURL,
  postOnFacebook,
  postOnLinkedIn,
  postOnTwitter,
  postOnWhatsApp
} from "../utils/UrlUtil";
import MapRoute from "./MapRoute";
import MapRouteImage from "./MapRouteImage";

const AdDetails = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const mapRouteRef = useRef();
  const headerHeight = useHeaderHeight();

  const [menuOpen, setMenuOpen] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);

  const { ad, isFetching } = useSelector(state => state.adReducer);
  let { templates, adsCategories: categories } = useSelector(
    state => state.entitiesReducer
  );
  const { lang } = useSelector(state => state.globalInformationsReducer);
  const { mapPhotoUri, files } = useSelector(state => state.captureReducer);
  const activities = useSelector(getActivities);

  let cityUri, adUri, id;
  if (route.params) {
    cityUri = route.params.cityUri;
    adUri = route.params.adUri;
    id = route.params.id;
  }

  templates = Object.keys(activities).length ? activities : templates;

  const imageWidth = SCREEN_WIDTH;

  useEffect(() => {
    if (Object.entries(categories).length === 0) {
      dispatch(fetchGetAdsCategories());
    }
    if (Object.entries(templates).length === 0) {
      dispatch(fetchGetTemplateDates());
      dispatch(fetchGetTemplateTimes());
      dispatch(fetchGetGoodDealsTemplates());

      setTimeout(() => {
        if (id) {
          dispatch(fetchGetAdDetails({ id }));
        } else {
          dispatch(fetchGetAdDetails({ cityUri, adUri }));
        }
      }, 500);
    } else {
      if (!ad) {
        if (id) {
          dispatch(fetchGetAdDetails({ id }));
        } else {
          dispatch(fetchGetAdDetails({ cityUri, adUri }));
        }
      }
    }
    return () =>
      dispatch({
        type: CLEAR_AD
      });
  }, []);

  useEffect(() => {
    if (ad && !imageHeight) {
      Image.getSize(ad.photoUri, (width, height) => {
        let proportion = height / width;
        setImageHeight(Math.ceil(imageWidth * proportion));
      });
    }
  }, [ad]);

  useEffect(() => {
    if (ad && Object.entries(categories).length) {
      navigation.setOptions({
        title: getTranslatedProperty(categories[ad.categoryId], "name")
      });
    }
  }, [ad, categories]);

  const instantSharing = () => {
    const template = Object.assign({}, templates[ad.templateId]);
    if (template && Object.keys(template).length) {
      template.choices = ad.choices;
      template.type = TYPE_GOODDEAL_ACTIVITY;
      template.photoUri = ad.photoUri;
      template.name = ad.title;
      template.description = ad.activityDescription;
      template.postDescription = ad.activityPostDescription;
      dispatch({
        type: SET_PUBLISH_GOODDEAL_TEMPLATE,
        template,
        ad,
        choiceId:
          template.choices && Object.keys(template.choices).length > 0
            ? Object.keys(template.choices)[0]
            : null
      });
      navigation.navigate("SelectTemplateDateScreen");
    }
  };

  const {
    bgColor1,
    bgColor2,
    row,
    rowReverse,
    flex1,
    white,
    justifyContentCenter,
    alignItemsCenter,
    m20,
    mb20,
    mt20,
    mv20,
    p20,
    ph20,
    ph10,
    h100p,
    font,
    fontBold,
    h44,
    rounded22,
    fs16
  } = commonStyles;

  return (
    <View style={[h100p, { height: SCREEN_HEIGHT - headerHeight }, bgColor1]}>
      {isFetching || !imageHeight || !ad ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <ScrollView
          style={[flex1, bgColor1]}
          contentContainerStyle={{
            paddingBottom: Constants.statusBarHeight + 70
          }}
          // stickyHeaderIndices={[0]}
          showsVerticalScrollIndicator={false}
        >
          <Image
            source={{ uri: ad.photoUri }}
            style={[
              {
                width: imageWidth,
                height: imageHeight,
                marginTop: 5
              }
            ]}
          />
          <View style={styles.titleView}>
            <View style={styles.promotionAndTitle}>
              <Text style={[styles.title, font]}>
                {getTranslatedProperty(ad, "title")}
              </Text>
              {Boolean(ad.promotion) && (
                <View style={rowReverse}>
                  <View style={[styles.promotionView]}>
                    <Text style={[styles.promotionText, font]}>
                      {getTranslatedProperty(ad, "promotion")}
                    </Text>
                    <View style={[flex1, bgColor1]} />
                  </View>
                </View>
              )}
            </View>
          </View>
          <Text style={[styles.description, font, mb20]}>
            {getTranslatedProperty(ad, "description")}
          </Text>
          {isWeb && (
            <View style={m20}>
              <View style={[rowReverse, { marginRight: 10 }]}>
                <Text style={fontBold}>{t("infos:share")}</Text>
              </View>
              <View style={[rowReverse]}>
                <SocialIcon
                  type="facebook"
                  onPress={() =>
                    postOnFacebook(
                      `https://www.hapicity.com/${cityUri}/vie-locale/${adUri}`,
                      getTranslatedProperty(ad, "description")
                    )
                  }
                />
                <SocialIcon
                  type="twitter"
                  onPress={() =>
                    postOnTwitter(
                      `https://www.hapicity.com/${cityUri}/vie-locale/${adUri}`,
                      getTranslatedProperty(ad, "description")
                    )
                  }
                />
                <SocialIcon
                  type="linkedin"
                  onPress={() =>
                    postOnLinkedIn(
                      `https://www.hapicity.com/${cityUri}/vie-locale/${adUri}`
                    )
                  }
                />
                <Icon
                  name="whatsapp"
                  type="font-awesome"
                  color="#25D366"
                  reverse
                  onPress={() =>
                    postOnWhatsApp(
                      `https://www.hapicity.com/${cityUri}/vie-locale/${adUri}`,
                      getTranslatedProperty(ad, "description")
                    )
                  }
                />
                <Icon
                  name="link"
                  type="font-awesome"
                  color={COLOR2}
                  reverse
                  onPress={() =>
                    Clipboard.setString(
                      `https://www.hapicity.com/${cityUri}/vie-locale/${adUri}`
                    )
                  }
                />
              </View>
            </View>
          )}
          <View style={[flex1, row, p20]}>
            <View style={[bgColor2, { width: 30, borderRadius: 15 }]} />
            <View style={[ph10, { paddingTop: 10, paddingBottom: 30 }, flex1]}>
              {Boolean(
                ad && ad.openingTime && ad.openingTime.trim().length > 0
              ) && (
                <View
                  style={[
                    mt20,
                    isSafari && {
                      flex: getTranslatedProperty(ad, "openingTime").split("\n")
                        .length
                    } // Workaround for safari
                  ]}
                >
                  <View style={[{ position: "absolute", left: -35 }]}>
                    <MaterialCommunityIcons
                      name="calendar-clock"
                      size={20}
                      color="white"
                    />
                  </View>
                  <Text style={[font, flex1]}>
                    {getTranslatedProperty(ad, "openingTime")}
                  </Text>
                </View>
              )}
              {Boolean(ad && ad.price && ad.price.trim().length > 0) && (
                <View
                  style={[
                    mt20,
                    isSafari && {
                      flex: getTranslatedProperty(ad, "price").split("\n")
                        .length
                    } // Workaround for safari
                  ]}
                >
                  <View style={[{ position: "absolute", left: -35 }]}>
                    <MaterialIcons name="euro-symbol" size={20} color="white" />
                  </View>
                  <Text style={[font, flex1]}>
                    {getTranslatedProperty(ad, "price")}
                  </Text>
                </View>
              )}
              {Boolean(ad && (ad.address || ad.zipCode || ad.city)) && (
                <View style={[mt20, flex1]}>
                  <View style={[{ position: "absolute", left: -35 }]}>
                    <MaterialIcons name="place" size={20} color="white" />
                  </View>
                  {Boolean(ad.address) && (
                    <Text
                      style={[font, flex1]}
                      onPress={() =>
                        ad.loc && mapRouteRef.current?.setVisibleTrue()
                      }
                    >
                      {ad.address}
                    </Text>
                  )}
                  <Text
                    style={[font, flex1]}
                    onPress={() =>
                      ad.loc && mapRouteRef.current?.setVisibleTrue()
                    }
                  >
                    {ad.zipCode} {ad.city}
                  </Text>
                </View>
              )}
              {Boolean(ad && ad.phone) && (
                <View style={[mt20]}>
                  <View style={[{ position: "absolute", left: -35 }]}>
                    <MaterialCommunityIcons
                      name="phone"
                      size={20}
                      color="white"
                    />
                  </View>
                  <Text
                    style={[font]}
                    onPress={() => openURL(dispatch, "telprompt:" + ad.phone)}
                  >
                    {ad.phone}
                  </Text>
                </View>
              )}
              {Boolean(
                ad && ad.webSiteUrl && ad.webSiteUrl.trim().length > 0
              ) && (
                <View style={[mt20, flex1]}>
                  <View style={[{ position: "absolute", left: -35 }]}>
                    <MaterialCommunityIcons
                      name="web"
                      size={20}
                      color="white"
                    />
                  </View>
                  <Text
                    style={[font, flex1]}
                    onPress={() => openBrowser(dispatch, ad.webSiteUrl)}
                  >
                    {ad.webSiteUrl}
                  </Text>
                </View>
              )}
              {Boolean(ad && ad.fbUrl && ad.fbUrl.trim().length > 0) && (
                <View style={[mt20, flex1]}>
                  <View style={[{ position: "absolute", left: -30 }]}>
                    <FontAwesome name="facebook" size={20} color="white" />
                  </View>
                  <Text
                    style={[font, flex1]}
                    onPress={() => openBrowser(dispatch, ad.fbUrl)}
                  >
                    {ad.fbUrl}
                  </Text>
                </View>
              )}
              {Boolean(ad && ad.email && ad.email.trim().length > 0) && (
                <View style={[mt20, flex1]}>
                  <View style={[{ position: "absolute", left: -35 }]}>
                    <FontAwesome name="at" size={20} color="white" />
                  </View>
                  <Text
                    style={[font, flex1]}
                    onPress={() => openURL(dispatch, "mailto:" + ad.email)}
                  >
                    {ad.email}
                  </Text>
                </View>
              )}
            </View>
          </View>
          {ad.templateId && (
            <Button
              icon={
                <MaterialCommunityIcons
                  name="heart-multiple"
                  size={24}
                  color="white"
                />
              }
              buttonStyle={[bgColor2, h44, rounded22, ph20]}
              containerStyle={[mv20, alignItemsCenter]}
              activeOpacity={0.8}
              title={t("button:instantsharing")}
              onPress={() => {
                instantSharing(templates, ad, dispatch, navigation);
              }}
              titleStyle={[white, fontBold, { paddingLeft: 5, fontSize: 16 }]}
            />
          )}
          {ad.loc && (
            <MapRoute
              ref={mapRouteRef}
              uri={ad.mapPhotoUri}
              longitude={ad.loc.coordinates[0]}
              latitude={ad.loc.coordinates[1]}
              title={getTranslatedProperty(ad, "title")}
              description={getTranslatedProperty(ad, "description")}
              lang={lang}
            />
          )}
          {Boolean(mapPhotoUri) && (
            <MapRouteImage
              uri={mapPhotoUri}
              callback={() =>
                dispatch(
                  fetchUpdateAdMapPhotoUri({ _id: ad._id, mapPhotoUri, files })
                )
              }
            />
          )}
        </ScrollView>
      )}
      <ActionButton
        buttonColor={menuOpen ? COLOR5 : COLOR2}
        degrees={90}
        renderIcon={() => (
          <MaterialCommunityIcons name="more" size={40} color="white" />
        )}
        onPressIn={() => setMenuOpen(true)}
        onReset={() => setMenuOpen(false)}
      >
        {isNative && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:share")}
            onPress={() =>
              dispatch({
                type: SHARE
              })
            }
            textStyle={[fontBold, fs16]}
          >
            <FontAwesome name="share-alt" size={34} color="white" />
          </ActionButton.Item>
        )}
        {Boolean(ad && ad.templateId) && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:instantsharing")}
            onPress={instantSharing}
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons
              name="heart-multiple"
              size={34}
              color="white"
            />
          </ActionButton.Item>
        )}
        {ad?.loc && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("mapRoute:dialogTitle")}
            onPress={() => mapRouteRef.current?.setVisibleTrue()}
            textStyle={[fontBold, fs16]}
          >
            <Fontisto name="map" size={34} color="white" />
          </ActionButton.Item>
        )}
        {Boolean(ad && ad.email && ad.email.trim().length > 0) && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:mailto")}
            onPress={() => openURL(dispatch, "mailto:" + ad.email)}
            textStyle={[fontBold, fs16]}
          >
            <FontAwesome name="at" size={34} color="white" />
          </ActionButton.Item>
        )}
        {Boolean(ad && ad.fbUrl && ad.fbUrl.trim().length > 0) && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:seefb")}
            onPress={() => openBrowser(dispatch, ad.fbUrl)}
            textStyle={[fontBold, fs16]}
          >
            <FontAwesome name="facebook" size={34} color="white" />
          </ActionButton.Item>
        )}
        {Boolean(ad && ad.webSiteUrl && ad.webSiteUrl.trim().length > 0) && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={t("button:seeweb")}
            onPress={() => openBrowser(dispatch, ad.webSiteUrl)}
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons name="web" size={34} color="white" />
          </ActionButton.Item>
        )}
        {Boolean(ad && ad.phone) && (
          <ActionButton.Item
            buttonColor={COLOR2}
            title={`${t("button:call")} ${ad.phone}`}
            onPress={() => openURL(dispatch, "telprompt:" + ad.phone)}
            textStyle={[fontBold, fs16]}
          >
            <MaterialCommunityIcons name="phone" size={40} color="white" />
          </ActionButton.Item>
        )}
      </ActionButton>
    </View>
  );
};

const MemoizedAdDetails = memo(AdDetails);
export default MemoizedAdDetails;

const styles = StyleSheet.create({
  title: {
    color: "#444041",
    fontSize: 25
  },
  titleView: {
    width: SCREEN_WIDTH - 40,
    marginRight: "auto",
    marginLeft: "auto"
  },
  promotionAndTitle: {
    marginTop: 10
  },
  promotionView: {
    backgroundColor: ORANGE_COLOR,
    marginTop: 10,
    height: 30,
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10
  },
  promotionText: {
    marginTop: 5,
    color: "#444041",
    fontSize: 16
  },
  description: {
    width: SCREEN_WIDTH - 40,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 12
  },
  bottomLine: {
    height: 1,
    backgroundColor: "black",
    width: SCREEN_WIDTH - 40,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 20
  },
  phoneText: {
    width: SCREEN_WIDTH - 40,
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 15
  },
  underlined: {
    textDecorationLine: "underline"
  },
  buttonText: {
    color: "white"
  },
  buttonView: {
    height: 44,
    width: 130,
    backgroundColor: COLOR3,
    marginRight: "auto",
    marginLeft: "auto",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 22,
    marginTop: 20,
    marginBottom: 20
  },
  phoneView: {
    flex: 1,
    marginRight: "auto",
    marginLeft: "auto"
  }
});
