import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import React, { memo } from "react";
import { Text, View } from "react-native";
import ActionButton from "../components/actionButton/ActionButton";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, { COLOR2, COLOR5 } from "../styles/commonStyles";
import LiveHeader from "./LiveHeader";
import LiveWeatherElement from "./LiveWeatherElement";

const LiveWeather = ({ item }) => {
  const navigation = useNavigation();

  const { event } = item;
  const { data } = event;

  const {
    bgWhite,
    font,
    w100p,
    p10,
    shadowGrey,
    mv5,
    row,
    flex1,
    borderBlack,
    borderBottomBlack,
    borderTopBlack,
    borderRightBlack,
    borderLeftBlack,
    mb10,
    darkgrey
  } = commonStyles;

  return (
    <View style={[bgWhite, shadowGrey, w100p, p10, mv5]}>
      <LiveHeader item={item} />

      <View style={[p10]}>
        <Text style={[font, mb10]}>
          {getTranslatedProperty(event, "description")}
        </Text>

        <View style={[mb10]}>
          <View style={[flex1, row]}>
            <LiveWeatherElement
              data={data}
              hour="9"
              customProps={[borderBlack]}
            />
            <LiveWeatherElement
              data={data}
              hour="12"
              customProps={[
                borderTopBlack,
                borderBottomBlack,
                borderRightBlack
              ]}
            />
          </View>

          <View style={[flex1, row]}>
            <LiveWeatherElement
              data={data}
              hour="15"
              customProps={[
                borderBottomBlack,
                borderRightBlack,
                borderLeftBlack
              ]}
            />
            <LiveWeatherElement
              data={data}
              hour="18"
              customProps={[borderBottomBlack, borderRightBlack]}
            />
          </View>
        </View>

        <View style={[row]}>
          <View style={[flex1]} />
          <Text style={[font, darkgrey]}>
            {getTranslatedProperty(event, "postMessage")}
          </Text>
        </View>
      </View>
      <ActionButton
        buttonColor={COLOR5}
        renderIcon={() => (
          <MaterialCommunityIcons
            name="dots-horizontal"
            size={40}
            color="white"
          />
        )}
        verticalOrientation="down"
        size={44}
        offsetX={18}
        offsetY={18}
        degrees={90}
        spacing={10}
      >
        <ActionButton.Item
          buttonColor={COLOR2}
          title={t("button:forecasts")}
          onPress={() => navigation.navigate("MeteoTab")}
          textStyle={font}
          textHeight={24}
        >
          <MaterialCommunityIcons
            name="weather-partly-cloudy"
            size={32}
            color="white"
          />
        </ActionButton.Item>
      </ActionButton>
    </View>
  );
};

const MemoizedLiveWeather = memo(LiveWeather);
export default MemoizedLiveWeather;
