import { Record } from "immutable";
import {
  API_START,
  CHAT,
  CLEAR_DROP_DOWN_ALERT_ERROR,
  CLEAR_DROP_DOWN_ALERT_INFO,
  CLEAR_DROP_DOWN_ALERT_MESSAGE,
  CLEAR_DROP_DOWN_ALERT_WARN,
  DELETE_USER_FAILURE,
  FETCH_BLOCK_USER,
  FETCH_DELETE_LIVE_EVENT,
  FETCH_DELETE_NOTIF_USER_TEMPLATE,
  FETCH_DELETE_USER,
  FETCH_EXIT_CHAT,
  FETCH_FAILURE,
  FETCH_FLAG_INAPPROPRIATE_CONTENT,
  FETCH_FLAG_INAPPROPRIATE_EVENT_CONTENT,
  FETCH_FORGET_PASSWORD,
  FETCH_GET_ADS,
  FETCH_GET_ADS_CATEGORIES,
  FETCH_GET_AD_DETAILS,
  FETCH_GET_CURRENT_METEO,
  FETCH_GET_EVENT_DETAILS,
  FETCH_GET_EVENT_INTERESTED_USERS,
  FETCH_GET_FORECAST_METEO,
  FETCH_GET_LIVE_EVENTS,
  FETCH_GET_NOTIF_STATUS,
  FETCH_GET_NOTIF_USER_TEMPLATES,
  FETCH_GET_USER_PROFILE,
  FETCH_INTERESTED_LIVE_EVENT,
  FETCH_LOGIN,
  FETCH_LOGIN_AS_GUEST,
  FETCH_LOGIN_WITH_FB,
  FETCH_LOGOUT,
  FETCH_PUBLISH_EVENT,
  FETCH_SET_NOTIF_ALL,
  FETCH_SET_NOTIF_SELECTION,
  FETCH_SET_NOTIF_USER_TEMPLATES,
  FETCH_SIGNUP,
  FETCH_SIGNUP_WITH_FB,
  FETCH_UNBLOCK_USER,
  FETCH_UNINTERESTED_LIVE_EVENT,
  FETCH_UPDATE_AD_MAP_PHOTO_URI,
  FETCH_UPDATE_COMMUNITY_MAP_PHOTO_URI,
  FETCH_UPDATE_PROFILE,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_RESULT,
  HIDE_SPLASH_SCREEN,
  LIVE,
  LOGIN_FAILURE,
  LOGOUT_FAILURE,
  SERVER_IN_MAINTENANCE,
  SET_ADS_CATEGORIES_FAILURE,
  SET_ADS_FAILURE,
  SET_AD_DETAILS_FAILURE,
  SET_CHAT_EXIT_RESULT,
  SET_COMMUNITY_DETAILS_FAILURE,
  SET_DROP_DOWN_ALERT_ERROR,
  SET_DROP_DOWN_ALERT_INFO,
  SET_DROP_DOWN_ALERT_WARN,
  SET_EVENT_INTERESTED_USERS_FAILURE,
  SET_HOBBIES_FAILURE,
  SET_IN_CHAT_WITH,
  SET_LOCATIONS_FAILURE,
  SET_MY_CHAT_USERS_FAILURE,
  SET_NOTIF_USER_TEMPLATES_FAILURE,
  SET_PROFILE_DETAILS,
  SET_PUBLISH_EVENT_FAILURE,
  SET_TEMPLATES,
  SET_TEMPLATES_FAILURE,
  SET_TEMPLATE_DATES_FAILURE,
  SET_TEMPLATE_TIMES_FAILURE,
  SET_USER_FAILURE,
  SIGNUP_RESULT,
  UPDATE_PROFILE_FAILURE,
  WS_EVENT
} from "../actions/types";
import { getImageUri } from "../assets/Images";
import { getTranslatedProperty, t } from "../services/i18n";
import { PURPLE_COLOR } from "../styles/commonStyles";

const InitialState = new Record({
  errors: {},
  dropDownAlertInfo: null,
  dropDownAlertError: null,
  dropDownAutoClearAlertError: null,
  dropDownAutoClearAlertWarn: null,
  dropDownAutoClearAlertInfo: null,
  dropDownAlertMessage: null,
  isMaintenance: false,
  inChatWith: null,
  templates: {},
  splashScreenHide: false,
  logout: false,
  userId: null
});
const initialState = new InitialState();

export default function errorReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START: {
      switch (action.payload) {
        case FETCH_LOGIN_AS_GUEST:
        case FETCH_LOGIN:
        case FETCH_SIGNUP:
        case FETCH_LOGIN_WITH_FB:
        case FETCH_FORGET_PASSWORD:
        case FETCH_SIGNUP_WITH_FB:
          return state
            .set("dropDownAlertError", null)
            .set("dropDownAlertInfo", null)
            .set("errors", {});
        case FETCH_GET_ADS:
        case FETCH_GET_AD_DETAILS:
        case FETCH_GET_ADS_CATEGORIES:
        case FETCH_GET_USER_PROFILE:
        case FETCH_UPDATE_PROFILE:
        case FETCH_FLAG_INAPPROPRIATE_CONTENT:
        case FETCH_FLAG_INAPPROPRIATE_EVENT_CONTENT:
        case FETCH_BLOCK_USER:
        case FETCH_UNBLOCK_USER:
        case FETCH_GET_CURRENT_METEO:
        case FETCH_GET_FORECAST_METEO:
        case FETCH_LOGOUT:
        case FETCH_DELETE_USER:
        case FETCH_GET_NOTIF_USER_TEMPLATES:
        case FETCH_SET_NOTIF_USER_TEMPLATES:
        case FETCH_PUBLISH_EVENT:
        case FETCH_DELETE_NOTIF_USER_TEMPLATE:
        case FETCH_GET_EVENT_INTERESTED_USERS:
        case FETCH_GET_NOTIF_STATUS:
        case FETCH_SET_NOTIF_ALL:
        case FETCH_SET_NOTIF_SELECTION:
        case FETCH_EXIT_CHAT:
        case FETCH_INTERESTED_LIVE_EVENT:
        case FETCH_UNINTERESTED_LIVE_EVENT:
        case FETCH_DELETE_LIVE_EVENT:
        case FETCH_GET_LIVE_EVENTS:
        case FETCH_GET_EVENT_DETAILS:
        case FETCH_UPDATE_AD_MAP_PHOTO_URI:
        case FETCH_UPDATE_COMMUNITY_MAP_PHOTO_URI:
          return state
            .set("dropDownAlertError", null)
            .set("dropDownAlertInfo", null);
        default:
          if (action.useDefaultFailure) {
            return state
              .set("dropDownAlertError", null)
              .set("dropDownAlertInfo", null);
          }
          break;
      }
      break;
    }

    case HIDE_SPLASH_SCREEN:
      return state.set("splashScreenHide", true);

    case SERVER_IN_MAINTENANCE:
      return state.set("splashScreenHide", true).set("isMaintenance", true);

    case SET_IN_CHAT_WITH:
      return state.set("inChatWith", action.value);

    case SET_CHAT_EXIT_RESULT:
      return state.set("inChatWith", null);

    case SET_TEMPLATES:
      return state.set("templates", action.entities.templates);

    case SET_PROFILE_DETAILS:
      return state.set("userId", action.payload._id);

    case WS_EVENT:
      if (action.payload.type === CHAT) {
        if (
          action.payload.from._id !== state.inChatWith &&
          action.payload.from._id !== state.userId &&
          action.payload.eventId !== state.inChatWith
        ) {
          const { _id, pseudo, firstname, photoUri, gender } =
            action.payload.from;
          const message =
            pseudo.indexOf("@") < 0
              ? {
                  title: "Message" + t("infos:from") + pseudo,
                  message: action.payload.text,
                  source: photoUri
                    ? photoUri
                    : gender === "F"
                    ? getImageUri("avatarF")
                    : getImageUri("avatar"),
                  eventId: action.payload.eventId,
                  pseudo,
                  from: pseudo
                }
              : {
                  title: "Message" + t("infos:from") + firstname,
                  message: action.payload.text,
                  source: photoUri
                    ? photoUri
                    : gender === "F"
                    ? getImageUri("avatarF")
                    : getImageUri("avatar"),
                  id: _id,
                  eventId: action.payload.eventId,
                  from: firstname
                };
          return state.set("dropDownAlertMessage", message);
        }
      } else if (action.payload.type === LIVE && action.payload.alert) {
        const message = {
          title: "Post" + t("infos:from") + action.payload.userId.pseudo,
          message: state.templates[action.payload.templateId]
            ? getTranslatedProperty(
                state.templates[action.payload.templateId],
                "description"
              )
            : "",
          activeStatusBarBackgroundColor: PURPLE_COLOR,
          source: action.payload.userId.photoUri
            ? action.payload.userId.photoUri
            : gender === "F"
            ? getImageUri("avatarF")
            : getImageUri("avatar"),
          id: action.payload.userId._id,
          from: action.payload.userId.pseudo
        };
        return state.set("dropDownAlertMessage", message);
      }
      break;

    case CLEAR_DROP_DOWN_ALERT_MESSAGE:
      return state.set("dropDownAlertMessage", null);

    case SIGNUP_RESULT:
      if (action.payload.message === "New account created") {
        return state.set("dropDownAlertInfo", "popup1");
      } else {
        return state.set("dropDownAlertInfo", "popup2");
      }

    case FORGET_PASSWORD_RESULT:
      return state.set("dropDownAlertInfo", "popup3");

    case FORGET_PASSWORD_FAILURE:
      if (("" + action.payload).endsWith("404")) {
        return state.set("dropDownAlertInfo", "unknownemail");
      }
      return state.set("dropDownAlertError", "unexpectederror");

    case SET_DROP_DOWN_ALERT_ERROR:
      return state
        .set("splashScreenHide", true)
        .set("dropDownAutoClearAlertError", action.error);

    case CLEAR_DROP_DOWN_ALERT_ERROR:
      return state.set("dropDownAutoClearAlertError", null);

    case SET_DROP_DOWN_ALERT_WARN:
      return state
        .set("splashScreenHide", true)
        .set("dropDownAutoClearAlertWarn", action.warn);

    case CLEAR_DROP_DOWN_ALERT_WARN:
      return state.set("dropDownAutoClearAlertWarn", null);

    case SET_DROP_DOWN_ALERT_INFO:
      return state
        .set("splashScreenHide", true)
        .set("dropDownAutoClearAlertInfo", action.info);

    case CLEAR_DROP_DOWN_ALERT_INFO:
      return state.set("dropDownAutoClearAlertInfo", null);

    case SET_USER_FAILURE:
    case SET_TEMPLATES_FAILURE:
    case SET_TEMPLATE_DATES_FAILURE:
    case SET_TEMPLATE_TIMES_FAILURE:
    case SET_LOCATIONS_FAILURE:
    case SET_ADS_CATEGORIES_FAILURE:
    case SET_HOBBIES_FAILURE:
    case SET_MY_CHAT_USERS_FAILURE:
      return state
        .set("splashScreenHide", true)
        .setIn(["errors", action.type], action.payload)
        .set("dropDownAlertError", "errorloadingdata")
        .set("logout", true);

    case SET_AD_DETAILS_FAILURE:
    case SET_ADS_FAILURE:
    case SET_ADS_CATEGORIES_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case FETCH_FAILURE:
    case DELETE_USER_FAILURE:
    case LOGOUT_FAILURE:
    case SET_NOTIF_USER_TEMPLATES_FAILURE:
    case SET_PUBLISH_EVENT_FAILURE:
    case SET_EVENT_INTERESTED_USERS_FAILURE:
    case SET_COMMUNITY_DETAILS_FAILURE:
      const errorMsg = action.payload.message || "";
      if (errorMsg === "Network Error") {
        return state.set("dropDownAlertError", "networkerror");
      } else if (errorMsg.endsWith("404")) {
        return state.set("dropDownAlertError", "error404");
      } else {
        return state.set("dropDownAlertError", "unexpectederror");
      }

    case LOGIN_FAILURE: {
      const errorMsg =
        action.payload && action.payload.message ? action.payload.message : "";
      if (errorMsg === "Network Error") {
        return state.set("dropDownAlertError", "networkerror");
      }
      if (errorMsg.endsWith("400") || errorMsg.endsWith("401")) {
        break;
      } else if (errorMsg.endsWith("403")) {
        return state.set("dropDownAlertError", "unauthorized");
      } else if (errorMsg.endsWith("404")) {
        return state.set("dropDownAlertError", "unknown");
      } else if (errorMsg.endsWith("406")) {
        return state.set("dropDownAlertInfo", "popup2");
      } else if (errorMsg.endsWith("423")) {
        return state.set("dropDownAlertInfo", "popup4");
      } else {
        return state.set("dropDownAlertError", "unexpectederror");
      }
    }
  }
  return state;
}
