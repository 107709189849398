import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import * as Location from "expo-location";
import React, { memo, useEffect, useRef, useState } from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import { Button } from "react-native-elements";
import MapView from "react-native-maps";
import SafeAreaView from "react-native-safe-area-view";
import MapViewCallout from "react-native-web-maps/src/Callout";
import MapViewMarker from "react-native-web-maps/src/Marker";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import {
  fetchGetCommunityDetails,
  fetchGetNearCommunities
} from "../actions/community";
import {
  CLEAR_COMMUNITIES,
  SET_DROP_DOWN_ALERT_ERROR,
  SET_DROP_DOWN_ALERT_INFO
} from "../actions/types";
import { getCommunityReducer } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, { COLOR2, isElectron } from "../styles/commonStyles";

const START_LATITUDE_DELTA = 0.8763;
const START_LONGITUDE_DELTA = 0.8284;

const END_LATITUDE_DELTA = 0.0763;
const END_LONGITUDE_DELTA = 0.1284;

const getSelector = createSelector(
  [getCommunityReducer],
  ({ currentCommunity, communities, threshold, coordinates }) => ({
    currentCommunity,
    communities,
    threshold,
    coordinates,
    communityId: currentCommunity?._id
  })
);

const CommunitiesMapModal = () => {
  const navigation = useNavigation();
  const mapRef = useRef();
  const markersRefs = useRef({});
  const userLocationMarkerRef = useRef();

  const { currentCommunity, communities, threshold, coordinates, communityId } =
    useSelector(getSelector, shallowEqual);
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [initialRegion, setInitialRegion] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [region, setRegion] = useState(null);

  if (!currentCommunity) {
    navigation.goBack();
  }

  const _getLocationAsync = async () => {
    try {
      setInitialRegion({
        ...initialRegion,
        latitudeDelta: START_LATITUDE_DELTA,
        longitudeDelta: START_LONGITUDE_DELTA
      });
      setIsFetching(true);
      const { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "locationinfo"
        });
      } else {
        const location = await Location.getCurrentPositionAsync({
          accuracy: Location.Accuracy.Low
        });
        setCurrentLocation({
          latitude: location.coords.latitude,
          longitude: location.coords.longitude
        });
        setTimeout(() => {
          setInitialRegion({
            ...initialRegion,
            latitudeDelta: START_LATITUDE_DELTA,
            longitudeDelta: START_LATITUDE_DELTA
          });
          mapRef.current.animateToRegion({
            latitude: location.coords.latitude,
            longitude: location.coords.longitude
          });
        }, 300);
        setTimeout(() => {
          setInitialRegion({
            ...initialRegion,
            latitudeDelta: END_LATITUDE_DELTA,
            longitudeDelta: END_LONGITUDE_DELTA
          });
          mapRef.current.animateToRegion({
            latitude: location.coords.latitude,
            longitude: location.coords.longitude
          });
        }, 1000);
      }
    } catch ({ message }) {
      dispatch({
        type: SET_DROP_DOWN_ALERT_ERROR,
        error: "locationerror",
        message
      });
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (currentCommunity.loc) {
      const [longitude, latitude] = currentCommunity.loc.coordinates;
      dispatch(fetchGetNearCommunities(longitude, latitude));
      setInitialRegion({
        latitudeDelta: START_LATITUDE_DELTA,
        longitudeDelta: START_LONGITUDE_DELTA,
        longitude,
        latitude
      });
      setTimeout(() => {
        setInitialRegion({
          ...initialRegion,
          latitudeDelta: END_LATITUDE_DELTA,
          longitudeDelta: END_LONGITUDE_DELTA
        });
        mapRef.current.animateToRegion({
          latitude,
          longitude
        });
      }, 500);
    }

    return () =>
      dispatch({
        type: CLEAR_COMMUNITIES
      });
  }, []);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const markerRefLength = Object.values(markersRefs.current).length;

  const prevMarkerRefLength = usePrevious(markerRefLength);

  useEffect(() => {
    if (!prevMarkerRefLength && markerRefLength) {
      const markerRef = markersRefs.current?.[communityId];
      if (markerRef) {
        markerRef.showCallout();
      }
    }
  }, [markerRefLength]);

  useEffect(() => {
    if (coordinates.length === 2) {
      const [longitude, latitude] = coordinates;
      dispatch(fetchGetNearCommunities(longitude, latitude));

      setInitialRegion({
        latitudeDelta: START_LATITUDE_DELTA,
        longitudeDelta: START_LONGITUDE_DELTA,
        longitude: region.longitude,
        latitude: region.latitude
      });

      setTimeout(() => {
        setInitialRegion({
          ...initialRegion,
          latitudeDelta: START_LATITUDE_DELTA,
          longitudeDelta: START_LONGITUDE_DELTA
        });
        mapRef?.current?.animateToRegion({
          longitude,
          latitude
        });
      }, 800);

      setTimeout(() => {
        setInitialRegion({
          ...initialRegion,
          latitudeDelta: END_LATITUDE_DELTA,
          longitudeDelta: END_LONGITUDE_DELTA
        });
        mapRef?.current?.animateToRegion({
          longitude,
          latitude
        });
      }, 1600);
    }
  }, [coordinates]);

  const prevCurrentCommunity = usePrevious(currentCommunity);

  useEffect(() => {
    if (
      prevCurrentCommunity &&
      currentCommunity &&
      JSON.stringify(currentCommunity) !== JSON.stringify(prevCurrentCommunity)
    ) {
      navigation.navigate("LandingScreen", {
        communityUri: currentCommunity.customUri
          ? currentCommunity.customUri
          : currentCommunity._id
      });
    }
  }, [prevCurrentCommunity, currentCommunity]);

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  const calcCrow = (lat1, lon1, lat2, lon2) => {
    var p = 0.017453292519943295; // Math.PI / 180
    var c = Math.cos;
    var a =
      0.5 -
      c((lat2 - lat1) * p) / 2 +
      (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  };

  const onRegionChange = newregion => {
    const { latitude, longitude } = newregion;
    setRegion(newregion);
    const { latitude: regionLatitude, longitude: regionLongitude } =
      region || initialRegion;
    const dist = calcCrow(regionLatitude, regionLongitude, latitude, longitude);
    if (dist > threshold) {
      dispatch(fetchGetNearCommunities(longitude, latitude));
      setInitialRegion({
        ...initialRegion,
        longitude,
        latitude
      });
    }
  };

  const {
    white,
    bgDarkgrey,
    shadowGrey,
    flex1,
    justifyContentCenter,
    alignItemsCenter,
    font,
    fontBold,
    fs18,
    w100p,
    rounded5
  } = commonStyles;

  return (
    <View style={flex1}>
      <SafeAreaView
        style={[
          w100p,
          alignItemsCenter,
          justifyContentCenter,
          {
            position: "absolute",
            top: 30,
            left: 0,
            zIndex: 3
          }
        ]}
      >
        <Button
          activeOpacity={0.8}
          title={t("button:locatecity")}
          onPress={() => navigation.navigate("CitiesModal")}
          titleStyle={[white, fontBold, fs18]}
          containerStyle={[rounded5, shadowGrey]}
          buttonStyle={bgDarkgrey}
        />
      </SafeAreaView>
      <SafeAreaView
        style={[
          w100p,
          alignItemsCenter,
          justifyContentCenter,
          {
            position: "absolute",
            bottom: 30,
            left: 0,
            zIndex: 3
          }
        ]}
      >
        <Button
          type="clear"
          activeOpacity={0.8}
          title={t("button:close")}
          onPress={() => navigation.navigate("LandingScreen")}
          titleStyle={[white, fontBold, fs18]}
          containerStyle={[rounded5, shadowGrey]}
          buttonStyle={bgDarkgrey}
        />
        {!isElectron && (
          <TouchableOpacity
            style={{
              position: "absolute",
              left: 40,
              zIndex: 2
            }}
            onPress={async () => await _getLocationAsync()}
            disabled={isFetching}
          >
            {isFetching ? (
              <ActivityIndicator size="large" color={COLOR2} />
            ) : (
              <MaterialIcons name="my-location" size={40} color={COLOR2} />
            )}
          </TouchableOpacity>
        )}
      </SafeAreaView>

      {initialRegion && (
        <MapView
          ref={mapRef}
          style={[flex1]}
          initialRegion={initialRegion}
          region={region}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            rotateControl: false,
            scaleControl: false
          }}
          onRegionChangeComplete={onRegionChange}
          showsUserLocation={Boolean(currentLocation)}
        >
          {communities &&
            Object.values(communities).map(
              ({ _id, name, longitude, latitude, subscribe }) => (
                <MapViewMarker
                  ref={element => (markersRefs.current[_id] = element)}
                  key={_id}
                  coordinate={{
                    longitude,
                    latitude
                  }}
                  title={name}
                  icon={{
                    url: subscribe
                      ? "https://mt.google.com/vt/icon/name=icons/spotlight/camping_v_L_8x.png"
                      : "https://mt.google.com/vt/icon/name=icons/spotlight/camping_search_v_L_8x.png"
                  }}
                  onPress={() => markersRefs.current[_id].showCallout()}
                >
                  <MapViewCallout>
                    <TouchableOpacity
                      onPress={() =>
                        _id === communityId
                          ? navigation.goBack()
                          : dispatch(fetchGetCommunityDetails(_id))
                      }
                    >
                      <Text style={font}>{name}</Text>
                    </TouchableOpacity>
                  </MapViewCallout>
                </MapViewMarker>
              )
            )}
          {currentLocation && (
            <MapViewMarker
              ref={userLocationMarkerRef}
              coordinate={{
                longitude: currentLocation.longitude,
                latitude: currentLocation.latitude
              }}
              title={t("infos:mylocation")}
              icon={{
                url: "https://mt.google.com/vt/icon?color=ff004C13&name=icons/spotlight/spotlight-waypoint-blue.png"
              }}
              onPress={() => userLocationMarkerRef.current?.showCallout()}
            >
              <MapViewCallout>
                <Text style={font}>{t("infos:mylocation")}</Text>
              </MapViewCallout>
            </MapViewMarker>
          )}
        </MapView>
      )}
    </View>
  );
};

const MemoizedCommunitiesMap = memo(CommunitiesMapModal);
export default MemoizedCommunitiesMap;
