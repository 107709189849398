import { useNavigation } from "@react-navigation/core";
import React, { memo } from "react";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { Avatar } from "react-native-elements";
import { shallowEqual, useSelector } from "react-redux";
import { getImageUri } from "../assets/Images";
import {
  GOOGLE_CLOUD_STORAGE_URL,
  GROUP_ID_WEATHER
} from "../config/constants";
import { getLiveHeaderSelector } from "../selectors";
import commonStyles from "../styles/commonStyles";

const LiveHeader = ({ item }) => {
  const navigation = useNavigation();

  const { userId, dateAdded, groupId } = item;

  const { user, users, dateStr } = useSelector(
    getLiveHeaderSelector(dateAdded),
    shallowEqual
  );

  const { pseudo, photoUri, countryCode, firstname, company, gender } =
    users[userId] || {};

  // Compute countryUri
  let countryUri;
  if (countryCode) {
    countryUri = GOOGLE_CLOUD_STORAGE_URL + "/Flags/" + countryCode + ".png";
  }

  // Compute from
  const from = company
    ? company
    : pseudo && pseudo.indexOf("@") >= 0
    ? firstname
    : pseudo;

  const {
    color4,
    darkgrey,
    bgLightgrey,
    row,
    flex1,
    alignItemsCenter,
    pr10,
    font,
    rounded30,
    h60,
    ml5
  } = commonStyles;

  const isMine = Boolean(user && user._id === userId);

  return (
    <View style={[bgLightgrey, row, h60, rounded30, alignItemsCenter, pr10]}>
      {userId ? (
        <TouchableOpacity
          onPress={() =>
            pseudo.indexOf("@") < 0
              ? navigation.navigate("ProfileScreen", {
                  pseudo
                })
              : navigation.navigate("ProfileTeamScreen", {
                  userId
                })
          }
          disabled={isMine || groupId === GROUP_ID_WEATHER}
        >
          <Avatar
            rounded
            size={60}
            source={
              photoUri
                ? { uri: photoUri }
                : gender === "F"
                ? getImageUri("avatarF")
                : getImageUri("avatar")
            }
            title={from ? from.toUpperCase().substr(0, 2) : "??"}
            activeOpacity={0.7}
          />
          <Image
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              height: 20,
              width: 20
            }}
            source={{ uri: countryUri }}
          />
        </TouchableOpacity>
      ) : (
        <View>
          <Avatar
            rounded
            size={60}
            source={
              photoUri
                ? { uri: photoUri }
                : gender === "F"
                ? getImageUri("avatarF")
                : getImageUri("avatar")
            }
            title={from ? from.toUpperCase().substr(0, 2) : "??"}
            activeOpacity={0.7}
          />
          <Image
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              height: 20,
              width: 20,
              borderRadius: 10
            }}
            source={{ uri: countryUri }}
          />
        </View>
      )}
      <View style={[ml5, flex1]}>
        {userId ? (
          <TouchableOpacity
            onPress={() =>
              pseudo.indexOf("@") < 0
                ? navigation.navigate("ProfileScreen", {
                    pseudo
                  })
                : navigation.navigate("ProfileTeamScreen", {
                    userId
                  })
            }
          >
            <Text style={[color4, font]}>{from}</Text>
          </TouchableOpacity>
        ) : (
          <Text style={[color4, font]}>{from}</Text>
        )}

        <Text refresh={dateStr} style={[darkgrey, font]}>
          {dateStr}
        </Text>
      </View>
    </View>
  );
};

const MemoizedLiveHeader = memo(LiveHeader);
export default MemoizedLiveHeader;
