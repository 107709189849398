import { Fontisto } from "@expo/vector-icons";
import React, { forwardRef } from "react";
import { ImageBackground, Text, TouchableOpacity, View } from "react-native";
import { t } from "../services/i18n";
import commonStyles, {
  DARKGREY_COLOR,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import MapLink from "./MapLink";

const MapRoute = forwardRef((props, ref) => {
  const { font, mh20, rounded10, overflowHidden } = commonStyles;
  const { uri } = props;
  return (
    <View>
      <MapLink ref={ref} {...props} />
      <ImageBackground
        style={[
          {
            height: 300,
            width: SCREEN_WIDTH - 40
          },
          rounded10,
          overflowHidden,
          mh20
        ]}
        source={{ uri }}
      >
        <TouchableOpacity
          style={{
            width: 200,
            zIndex: 999,
            position: "absolute",
            bottom: 60,
            right: SCREEN_WIDTH / 2 - 100
          }}
          onPress={() => ref.current.setVisibleTrue()}
        >
          <Fontisto.Button
            name="map"
            size={30}
            color={DARKGREY_COLOR}
            backgroundColor={"rgba(255, 255, 255, 0.4)"}
            borderRadius={25}
            onPress={() => ref.current?.setVisibleTrue()}
          >
            <Text style={[font]}>{t("infos:clickmap")}</Text>
          </Fontisto.Button>
        </TouchableOpacity>
      </ImageBackground>
    </View>
  );
});

export default MapRoute;
