import Constants from "expo-constants";
import { isElectron, isWeb } from "../styles/commonStyles";
import isDevelopment from "../utils/electron-is-dev";

const ENV = {
  dev: {
    // apiUrl: "http://192.168.1.74:3001",
    // wsUrl: "ws://192.168.1.74:3001"
    // apiUrl: "http://192.168.1.61:3001",
    // wsUrl: "ws://192.168.1.61:3001",
    // deepLinkUrl: "exp://192.168.1.61:19000/--/"
    apiUrl: "http://localhost:3001",
    wsUrl: "ws://localhost:3001",
    deepLinkUrl: "exp://localhost:19000/--/"
    // apiUrl: "https://server.digihapi.com",
    // wsUrl: "wss://server.digihapi.com"
    // apiUrl: "https://digihapi-staging.herokuapp.com",
    // wsUrl: "wss://digihapi-staging.herokuapp.com"
  },
  staging: {
    // apiUrl: "https://server.digihapi.com",
    // wsUrl: "wss://server.digihapi.com"
    apiUrl: "https://digihapi-staging.herokuapp.com",
    wsUrl: "wss://digihapi-staging.herokuapp.com"
    // apiUrl: "http://localhost:3001",
    // wsUrl: "ws://localhost:3001"
  },
  prod: {
    // apiUrl: "https://server.digihapi.com",
    // wsUrl: "wss://server.digihapi.com"
    apiUrl: "https://digihapi-staging.herokuapp.com",
    wsUrl: "wss://digihapi-staging.herokuapp.com"
  }
};

function getEnvVars(env = "") {
  if (isWeb) {
    if ((isElectron && isDevelopment) || (!isElectron && __DEV__)) {
      return ENV.dev;
    } else {
      return ENV.prod;
    }
  }
  if (env === null || env === undefined || env === "") return ENV.dev;
  if (env.indexOf("dev") !== -1) return ENV.dev;
  if (env.indexOf("staging") !== -1) return ENV.staging;
  if (env.indexOf("prod") !== -1) return ENV.prod;
}

export default getEnvVars(Constants.manifest.releaseChannel);
