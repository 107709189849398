import {
  FontAwesome,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/core";
import { useHeaderHeight } from "@react-navigation/elements";
import * as ImagePicker from "expo-image-picker";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { memo } from "react";
import {
  ActivityIndicator,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Text,
  View
} from "react-native";
import CountryPicker from "react-native-country-picker-modal";
import { Avatar, CheckBox, Input } from "react-native-elements";
import { TextInputMask } from "react-native-masked-text";
import SafeAreaView from "react-native-safe-area-view";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchDeleteUser } from "../actions/login";
import { fetchCheckPseudo, fetchUpdateProfile } from "../actions/profile";
import {
  CLEAR_PHOTO,
  CLEAR_PROFILE_VALIDATE,
  CLEAR_VALIDATE_PSEUDO,
  EDIT_PROFILE,
  HIDE_SPLASH_SCREEN,
  LOGOUT,
  NOT_UPDATE_AUTOMATICALLY_HOBBIES,
  REGISTER_FOR_PUSH_NOTIF,
  RESET_DATA,
  SET_ADDRESS,
  SET_BIRTHDATE,
  SET_CITY,
  SET_COUNTRY_CODE,
  SET_DATE_RANGE,
  SET_DESCRIPTION,
  SET_DROP_DOWN_ALERT_INFO,
  SET_FIRST_NAME,
  SET_GENDER,
  SET_LAST_NAME,
  SET_LOCATION_ID,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_CONFIRMATION,
  SET_PHONE,
  SET_PHOTO_URI,
  SET_PSEUDO,
  SET_USER_HOBBIES,
  SET_VALIDATE_FALSE,
  SET_ZIP_CODE,
  UPDATE_AUTOMATICALLY_HOBBIES,
  VALIDATE_PROFILE_INPUTS,
  VALIDATE_PSEUDO
} from "../actions/types";
import { getImageUri } from "../assets/Images";
import ActionButton from "../components/actionButton/ActionButton";
import CommonPicker from "../components/CommonPicker";
import ConfirmationModal from "../components/ConfirmationModal";
import ComposePicker from "../components/dateRanges/ComposePicker";
import Hobbies from "../components/Hobbies";
import { ROLE_CAMPER } from "../config/constants";
import { getProfileUpdateSelector } from "../selectors";
import { t } from "../services/i18n";
import commonStyles, {
  BLUE_COLOR,
  COLOR2,
  COLOR5,
  isiOS,
  isNative,
  isWeb,
  LIGHTLIGHTGREY_COLOR,
  PINK_COLOR,
  SCREEN_HEIGHT
} from "../styles/commonStyles";
const IMAGE_SIZE = 200;

const ProfileUpdate = () => {
  const headerHeight = useHeaderHeight();
  const pseudoInputRef = useRef();

  const navigation = useNavigation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const {
    files,
    pseudo,
    firstname,
    lastname,
    gender,
    birthdate,
    address,
    zipCode,
    city,
    countryCode,
    phone,
    description,
    photoUri,
    hobbiesIds,
    startDate,
    endDate,
    locationId,
    pseudoErrorMsg,
    firstnameErrorMsg,
    lastnameErrorMsg,
    birthdayErrorMsg,
    daterangeErrorMsg,
    locationErrorMsg,
    user,
    isValid,
    isFetching,
    dateRangeChanged,
    locationIdChanged,
    firstLogin,
    needPseudoCheck,
    clearProfileValidate,
    isPseudoClearValidate,
    phoneErrorMsg,
    needRegisterForPush,
    updateHobbies,
    strategy,
    newpassword,
    newpasswordconfirmation,
    passwordErrorMsg,
    passwordConfirmationErrorMsg,
    locale,
    locations,
    deleteUserResult
  } = useSelector(getProfileUpdateSelector, shallowEqual);

  const role = user?.role;

  useEffect(() => {
    dispatch({
      type: EDIT_PROFILE
    });
    if (isNative) {
      dispatch({
        type: HIDE_SPLASH_SCREEN
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (deleteUserResult) {
      (async () => {
        await AsyncStorage.removeItem("emailOrPseudo");
        await AsyncStorage.removeItem("hideTooltips");
        await AsyncStorage.removeItem("strategy");
        dispatch({
          type: LOGOUT
        });
        dispatch({
          type: RESET_DATA
        });
      })();
    }
  }, [deleteUserResult]);

  useEffect(() => {
    if (isPseudoClearValidate) {
      dispatch({
        type: VALIDATE_PSEUDO
      });
    } else if (!pseudoErrorMsg && needPseudoCheck) {
      dispatch(fetchCheckPseudo({ pseudo }));
    }
  }, [isPseudoClearValidate]);

  useEffect(() => {
    if (pseudoErrorMsg) {
      pseudoInputRef.currrent?.shake();
      pseudoInputRef.currrent?.focus();
    }
  }, [pseudoErrorMsg]);

  useEffect(() => {
    if (files) {
      pseudoInputRef.currrent?.focus();
    }
  }, [files]);

  useEffect(() => {
    if (clearProfileValidate) {
      dispatch({
        type: VALIDATE_PROFILE_INPUTS
      });
    }
  }, [clearProfileValidate]);

  useEffect(() => {
    if (isValid) {
      dispatch(
        fetchUpdateProfile(
          {
            photoUri: photoUri && !files.length ? photoUri : null,
            pseudo,
            firstname,
            lastname,
            gender,
            address,
            zipCode,
            files,
            city,
            countryCode,
            phone,
            description,
            birthdate,
            hobbiesIds: [...hobbiesIds],
            updateHobbies,
            newpassword
          },
          locationIdChanged || dateRangeChanged
            ? {
                startDate,
                endDate,
                locationId
              }
            : null
        )
      );
    }
  }, [isValid]);

  useEffect(() => {
    if (isValid && user) {
      navigation.navigate("TabScreen");
      if (needRegisterForPush) {
        dispatch({
          type: REGISTER_FOR_PUSH_NOTIF
        });
      }
      dispatch({
        type: SET_VALIDATE_FALSE
      });
    }
  }, [user]);

  const deleteUser = () => {
    dispatch(fetchDeleteUser());
  };

  const _pickImage = async () => {
    if (!isWeb) {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "photosinfo"
        });
        return;
      }
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: "Images",
      // allowsEditing: true,
      aspect: [1, 1],
      quality: 0.1,
      base64: true
    });

    if (!result.cancelled) {
      if (isWeb) {
        const files = [];
        const name = "" + Date.now();
        files.push({
          name,
          base64: result.uri
        });
        dispatch({
          type: SET_PHOTO_URI,
          value: result.uri,
          files
        });
      } else {
        const files = [];
        const filename = result.uri.split("/").pop();
        const name = filename
          ? filename.substring(0, filename.lastIndexOf("."))
          : null;
        files.push({
          name,
          base64: "data:image/jpeg;base64," + result.base64
        });
        dispatch({
          type: SET_PHOTO_URI,
          value: result.uri,
          files
        });
      }
    }
  };

  const cancel = () => {
    if (user?.needUpdate) {
      dispatch({
        type: LOGOUT
      });
    } else if (navigation.canGoBack()) {
      navigation.goBack();
    }
  };

  const {
    red,
    bgColor1,
    darkgrey,
    bgWhite,
    color2,
    color5,
    flex1,
    alignItemsCenter,
    justifyContentCenter,
    fs12,
    fs16,
    fs20,
    row,
    p10,
    p20,
    ml10,
    mt10,
    mb10,
    mb20,
    mv10,
    mv20,
    ph10,
    font,
    fontBold,
    shadowGrey,
    w100p,
    ml5,
    mt5
  } = commonStyles;

  return (
    <KeyboardAvoidingView
      {...(isiOS && { behavior: "padding" })}
      style={[[w100p, { height: SCREEN_HEIGHT - headerHeight }, bgColor1]]}
    >
      <ConfirmationModal
        description={t("settings:confirmdelete")}
        onClosed={() => setShowConfirmationModal(false)}
        title={t("menu:confirmation")}
        showConfirmationModal={showConfirmationModal}
        onConfirmation={deleteUser}
      />
      {isFetching || isLoading ? (
        <ActivityIndicator
          style={[flex1, justifyContentCenter]}
          size="large"
          color={COLOR2}
        />
      ) : (
        <SafeAreaView style={[flex1]}>
          <ScrollView
            style={[flex1]}
            contentContainerStyle={{ paddingBottom: 90 }}
            showsVerticalScrollIndicator={false}
          >
            <View
              style={[
                w100p,
                p10,
                bgWhite,
                mt10,
                mb20,
                shadowGrey,
                alignItemsCenter
              ]}
            >
              <Text style={[fontBold, color5, fs20, mv20]}>
                {t("profile:profile")}
              </Text>

              <View style={[row, mb20, justifyContentCenter, w100p]}>
                <Avatar
                  rounded
                  size={IMAGE_SIZE}
                  title={pseudo ? pseudo.toUpperCase().substr(0, 2) : "?"}
                  source={
                    Boolean(photoUri)
                      ? {
                          uri: photoUri
                        }
                      : gender === "M"
                      ? getImageUri("avatar")
                      : getImageUri("avatarF")
                  }
                  onPress={() => {
                    Keyboard.dismiss();
                    navigation.navigate("CameraScreen", {
                      from: "profile"
                    });
                  }}
                />
                <ActionButton
                  buttonColor={COLOR5}
                  renderIcon={() => (
                    <MaterialCommunityIcons
                      name="dots-horizontal"
                      size={34}
                      color="white"
                    />
                  )}
                  verticalOrientation="down"
                  size={44}
                  offsetX={10}
                  offsetY={0}
                  degrees={90}
                  spacing={10}
                >
                  {Boolean(photoUri) && (
                    <ActionButton.Item
                      buttonColor={"red"}
                      title={t("button:delete")}
                      onPress={() => dispatch({ type: CLEAR_PHOTO })}
                      textStyle={font}
                      textHeight={24}
                    >
                      <MaterialCommunityIcons
                        name={"delete-forever"}
                        size={34}
                        color="white"
                      />
                    </ActionButton.Item>
                  )}
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={t("button:albums")}
                    onPress={_pickImage}
                    textStyle={font}
                    textHeight={24}
                  >
                    <FontAwesome name="image" size={28} color="white" />
                  </ActionButton.Item>
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={t("button:camera")}
                    onPress={() => {
                      Keyboard.dismiss();
                      navigation.navigate("CameraScreen", {
                        from: "profile"
                      });
                    }}
                    textStyle={font}
                    textHeight={24}
                  >
                    <MaterialIcons name="camera-alt" size={34} color="white" />
                  </ActionButton.Item>
                </ActionButton>
              </View>
              {role === ROLE_CAMPER && (
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  onChangeText={value => dispatch({ type: SET_PSEUDO, value })}
                  onBlur={() => {
                    dispatch({
                      type: CLEAR_VALIDATE_PSEUDO
                    });
                  }}
                  placeholder={t("profile:pseudo")}
                  keyboardType="default"
                  autoCorrect={false}
                  autoCapitalize="none"
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={pseudo}
                  ref={pseudoInputRef}
                  errorMessage={pseudoErrorMsg}
                />
              )}
              <View style={[mv10, row, alignItemsCenter]}>
                <CheckBox
                  title={t("profile:male")}
                  textStyle={[{ color: BLUE_COLOR }, fontBold]}
                  checkedColor={BLUE_COLOR}
                  uncheckedColor={BLUE_COLOR}
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  checked={gender === "M"}
                  onPress={() => dispatch({ type: SET_GENDER, value: "M" })}
                />
                <CheckBox
                  title={t("profile:female")}
                  textStyle={[{ color: PINK_COLOR }, fontBold]}
                  checkedColor={PINK_COLOR}
                  uncheckedColor={PINK_COLOR}
                  checkedIcon="dot-circle-o"
                  uncheckedIcon="circle-o"
                  checked={gender === "F"}
                  onPress={() => dispatch({ type: SET_GENDER, value: "F" })}
                />
              </View>
              <Input
                inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                onChangeText={value =>
                  dispatch({ type: SET_FIRST_NAME, value })
                }
                placeholder={t("profile:firstname")}
                keyboardType="default"
                autoCorrect={false}
                autoCapitalize="words"
                keyboardAppearance="light"
                returnKeyType="next"
                value={firstname}
                errorMessage={firstnameErrorMsg}
              />
              <Input
                inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                onChangeText={value => dispatch({ type: SET_LAST_NAME, value })}
                containerStyle={mb20}
                placeholder={t("profile:lastname")}
                autoCorrect={false}
                autoCapitalize="words"
                value={lastname}
                keyboardType="default"
                returnKeyType="next"
                errorMessage={lastnameErrorMsg}
              />
              {role === ROLE_CAMPER && (
                <Fragment>
                  <View style={[{ paddingHorizontal: 10 }, w100p]}>
                    <TextInputMask
                      type={Platform.OS === "web" ? "custom" : "datetime"}
                      options={
                        Platform.OS === "web"
                          ? {
                              mask: "99/99/9999"
                            }
                          : {
                              format: "DD/MM/YYYY"
                            }
                      }
                      placeholder={t("profile:birthdate")}
                      value={birthdate}
                      style={[
                        font,
                        {
                          paddingLeft: isiOS ? 10 : 0,
                          paddingBottom: 10,
                          fontSize: 18,
                          height: 40,
                          width: "100%",
                          borderColor: "gray",
                          borderBottomWidth: 1
                        },
                        !birthdayErrorMsg && mb20
                      ]}
                      onChangeText={value =>
                        dispatch({ type: SET_BIRTHDATE, value })
                      }
                    />
                    {birthdayErrorMsg && (
                      <Text style={[ml5, mt5, red, fs12, mb20]}>
                        {birthdayErrorMsg}
                      </Text>
                    )}
                  </View>
                  <Input
                    inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                    onChangeText={value =>
                      dispatch({ type: SET_ADDRESS, value })
                    }
                    placeholder={t("profile:address")}
                    autoCorrect={false}
                    keyboardType="default"
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={address}
                  />
                  <Input
                    inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                    onChangeText={value =>
                      dispatch({ type: SET_ZIP_CODE, value })
                    }
                    placeholder={t("profile:zipcode")}
                    autoCorrect={false}
                    keyboardType="default"
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={zipCode}
                  />
                  <Input
                    inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                    containerStyle={mb20}
                    onChangeText={value => dispatch({ type: SET_CITY, value })}
                    placeholder={t("profile:city")}
                    autoCorrect={false}
                    keyboardType="default"
                    keyboardAppearance="light"
                    returnKeyType="next"
                    value={city}
                  />
                  <View style={[flex1, alignItemsCenter, justifyContentCenter]}>
                    <View style={[row, mb20, ph10, alignItemsCenter]}>
                      <CountryPicker
                        {...{
                          countryCode,
                          translation: locale === "fr" ? "fra" : "common",
                          withFlagButton: true,
                          withFilter: true,
                          onSelect: value =>
                            dispatch({
                              type: SET_COUNTRY_CODE,
                              value: value.cca2
                            })
                        }}
                      />
                      <Text style={[font, fs20]}>
                        {"  " + t("country:" + countryCode)}
                      </Text>
                    </View>
                  </View>
                </Fragment>
              )}
              {!countryCode || countryCode === "FR" ? (
                <View
                  style={[
                    { paddingHorizontal: 10 },
                    w100p,
                    role === ROLE_CAMPER && mb20
                  ]}
                >
                  <TextInputMask
                    type={"custom"}
                    options={{
                      mask: "+33 (0)9 99 99 99 99"
                    }}
                    keyboardType="phone-pad"
                    placeholder={t("profile:phone")}
                    value={phone}
                    style={[
                      font,
                      {
                        paddingLeft: isiOS ? 10 : 0,
                        paddingBottom: 10,
                        fontSize: 18,
                        height: 30,
                        width: "100%",
                        borderColor: "gray",
                        borderBottomWidth: 1
                      }
                    ]}
                    onChangeText={value => dispatch({ type: SET_PHONE, value })}
                  />
                  {phoneErrorMsg && (
                    <Text style={[ml5, mt5, red, fs12]}>{phoneErrorMsg}</Text>
                  )}
                </View>
              ) : (
                <View
                  style={[
                    { paddingHorizontal: 10 },
                    w100p,
                    role === ROLE_CAMPER && mb20
                  ]}
                >
                  <TextInputMask
                    type={"cel-phone"}
                    options={{
                      maskType: "INTERNATIONAL"
                    }}
                    placeholder={t("profile:phone")}
                    value={phone}
                    style={[
                      font,
                      {
                        paddingLeft: isiOS ? 10 : 0,
                        paddingBottom: 10,
                        fontSize: 18,
                        height: 40,
                        width: "100%",
                        borderColor: "gray",
                        borderBottomWidth: 1
                      }
                    ]}
                    onChangeText={value => dispatch({ type: SET_PHONE, value })}
                  />
                </View>
              )}
              {role !== ROLE_CAMPER && (
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb20}
                  onChangeText={value =>
                    dispatch({ type: SET_DESCRIPTION, value })
                  }
                  placeholder={t("profile:description")}
                  keyboardType="default"
                  keyboardAppearance="light"
                  returnKeyType="next"
                  value={description}
                />
              )}
            </View>
            {role === ROLE_CAMPER && (
              <Fragment>
                <View
                  style={[
                    w100p,
                    p20,
                    bgWhite,
                    mt10,
                    mb20,
                    shadowGrey,
                    alignItemsCenter
                  ]}
                >
                  <Text style={[fontBold, color5, fs20, mv20]}>
                    {t("profile:hobbies")}
                  </Text>
                  <Hobbies
                    hobbiesIds={new Set(hobbiesIds)}
                    onHobbiesChange={value =>
                      dispatch({ type: SET_USER_HOBBIES, value })
                    }
                  />
                  <CheckBox
                    title={t("profile:updatehobbies")}
                    textStyle={[darkgrey, fontBold]}
                    containerStyle={[w100p]}
                    checkedColor={COLOR2}
                    uncheckedColor={COLOR2}
                    checkedIcon="dot-circle-o"
                    uncheckedIcon="circle-o"
                    checked={updateHobbies}
                    onPress={() =>
                      dispatch({ type: UPDATE_AUTOMATICALLY_HOBBIES })
                    }
                  />
                  <CheckBox
                    title={t("profile:noupdatehobbies")}
                    textStyle={[darkgrey, fontBold]}
                    containerStyle={[w100p]}
                    checkedColor={COLOR2}
                    uncheckedColor={COLOR2}
                    checkedIcon="dot-circle-o"
                    uncheckedIcon="circle-o"
                    checked={!updateHobbies}
                    onPress={() =>
                      dispatch({ type: NOT_UPDATE_AUTOMATICALLY_HOBBIES })
                    }
                  />
                </View>
                <View style={[w100p, p20, bgWhite, mt10, mb20, shadowGrey]}>
                  <View style={[alignItemsCenter]}>
                    <Text style={[fontBold, color5, fs20, mv20]}>
                      {t("profile:stay")}
                    </Text>
                  </View>
                  <View style={mb20}>
                    <ComposePicker
                      // style={{ width: "100%", height: 44 }}
                      customStyles={{
                        placeholderText: {
                          fontSize: 18,
                          fontFamily: "font"
                        }, // placeHolder style
                        headerStyle: { backgroundColor: COLOR5 }, // title container style
                        headerMarkTitle: {
                          fontFamily: "font",
                          color: COLOR2
                        }, // title mark style
                        headerDateTitle: {}, // title Date style
                        contentInput: {
                          backgroundColor: LIGHTLIGHTGREY_COLOR
                        }, //content text container style
                        contentText: font //after selected text Style
                      }} // optional
                      selectedBgColor={COLOR2}
                      outFormat="DD/MM/YYYY"
                      headFormat="DD/MM/YYYY"
                      returnFormat="DD/MM/YYYY"
                      markText={t("profile:selectdaterange")}
                      centerAlign // optional text will align center or not
                      allowFontScaling={false} // optional
                      placeholder={t("profile:bookingdates")}
                      onConfirm={value =>
                        dispatch({
                          type: SET_DATE_RANGE,
                          value
                        })
                      }
                      startDate={startDate}
                      endDate={endDate}
                      mode={"range"}
                    />
                    {daterangeErrorMsg && (
                      <Text style={[red, fs12]}>{daterangeErrorMsg}</Text>
                    )}
                  </View>

                  <CommonPicker
                    style={!locationErrorMsg && mb20}
                    data={locations}
                    firstPickerLabel={t("profile:selectaccomodation")}
                    selectedValue={locationId}
                    onChange={value =>
                      dispatch({
                        type: SET_LOCATION_ID,
                        value
                      })
                    }
                  />
                  {locationErrorMsg && (
                    <Text style={[red, fs12, mb20]}>{locationErrorMsg}</Text>
                  )}
                </View>
              </Fragment>
            )}
            {strategy === "jwt" && (
              <View
                style={[
                  w100p,
                  p20,
                  bgWhite,
                  mt10,
                  mb20,
                  shadowGrey,
                  alignItemsCenter
                ]}
              >
                <Text style={[fontBold, color2, fs20, mv20]}>
                  {t("profile:password")}
                </Text>
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb10}
                  onChangeText={value =>
                    dispatch({ type: SET_NEW_PASSWORD, value })
                  }
                  placeholder={t("profile:newpassword")}
                  keyboardType="default"
                  keyboardAppearance="light"
                  autoCapitalize="none"
                  autoCorrect={false}
                  secureTextEntry={true}
                  returnKeyType="next"
                  value={newpassword}
                  errorMessage={passwordErrorMsg}
                />
                <Input
                  inputStyle={[isiOS ? ml10 : null, darkgrey, font]}
                  containerStyle={mb10}
                  onChangeText={value =>
                    dispatch({ type: SET_NEW_PASSWORD_CONFIRMATION, value })
                  }
                  placeholder={t("profile:newpasswordconfirmation")}
                  keyboardType="default"
                  keyboardAppearance="light"
                  autoCapitalize="none"
                  autoCorrect={false}
                  secureTextEntry={true}
                  returnKeyType="done"
                  value={newpasswordconfirmation}
                  errorMessage={passwordConfirmationErrorMsg}
                />
              </View>
            )}
          </ScrollView>
          <ActionButton
            buttonColor={menuOpen ? COLOR5 : COLOR2}
            degrees={90}
            renderIcon={() => (
              <MaterialCommunityIcons
                name="dots-horizontal"
                size={40}
                color="white"
              />
            )}
            onPressIn={() => setMenuOpen(true)}
            onReset={() => setMenuOpen(false)}
            disabled={isFetching}
          >
            <ActionButton.Item
              buttonColor={"red"}
              title={t("button:deleteaccount")}
              onPress={() => setShowConfirmationModal(true)}
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons
                name="account-remove"
                size={36}
                color="white"
              />
            </ActionButton.Item>
            <ActionButton.Item
              buttonColor={"red"}
              title={t("button:cancel")}
              onPress={() => cancel()}
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons name="cancel" size={36} color="white" />
            </ActionButton.Item>
            <ActionButton.Item
              buttonColor={COLOR2}
              title={firstLogin ? t("button:signup") : t("button:update")}
              onPress={() =>
                dispatch({
                  type: CLEAR_PROFILE_VALIDATE
                })
              }
              textStyle={[fontBold, fs16]}
            >
              <MaterialCommunityIcons
                name="account-check"
                size={36}
                color="white"
              />
            </ActionButton.Item>
          </ActionButton>
        </SafeAreaView>
      )}
    </KeyboardAvoidingView>
  );
};

const MemoizedProfileUpdate = memo(ProfileUpdate);
export default MemoizedProfileUpdate;
