import { isElectron } from "../styles/commonStyles";

export let Images = {
  // buttons
  back: require("./images/Back.png"),
  plus: require("./images/ButtonPlus.png"),

  splash: require("./splash.png"),
  logo: require("./images/logo.png"),

  icon: require("./images/icon_notif.png")
};

let CachedImages = {};
export const setCachedImages = data => {
  CachedImages = { ...data };
  Images = {
    ...Images,
    ...CachedImages
  };
};

export const getImage = name => {
  return isElectron ? Images[name].default : Images[name];
};

export const getImageUri = name => {
  return { uri: Images[name] };
};
