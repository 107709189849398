import { Platform } from "react-native";
import { parse } from "search-params";
import {
  API_END,
  API_START,
  CLEAR_INITIAL_LINK,
  FETCH_GET_GLOBAL_INFORMATIONS,
  FETCH_GET_GLOBAL_INFORMATIONS_VERSION,
  RESET_DATA,
  SET_GLOBAL_INFORMATIONS,
  SET_GLOBAL_INFORMATIONS_FAILURE,
  SET_GLOBAL_INFORMATIONS_VERSIONS,
  SET_INITIAL_LINK,
  SET_INITIAL_URL,
  SET_INSTALLATION_ID,
  SET_LANG,
  SET_PROFILE_DETAILS
} from "../actions/types";

const initialState = {
  isFetching: false,
  datetimeDiff: 0,
  clientVersion: null,
  serverVersion: null,
  dropDownAlertWarn: null,
  dropDownAlertError: null,
  accessDenied: false,
  obsoleteversion: false,
  needupdate: false,
  forceupdate: false,
  lang: "fr",
  initialUrl: null,
  initialLink: null,
  installationId: null
};

export default function globalInformationsReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case API_START: {
      switch (action.payload) {
        case FETCH_GET_GLOBAL_INFORMATIONS_VERSION:
          return {
            ...state,
            isFetching: true,
            dropDownAlertError: null,
            dropDownAlertWarn: null,
            dropDownAlertInfo: null
          };

        case FETCH_GET_GLOBAL_INFORMATIONS:
          return {
            ...state,
            isFetching: true,
            dropDownAlertError: null,
            dropDownAlertWarn: null,
            dropDownAlertInfo: null,
            accessDenied: false
          };
        default:
          // Do nothing
          break;
      }
      break;
    }

    case SET_INITIAL_URL:
      return {
        ...state,
        initialUrl: action.value
      };

    case SET_INITIAL_LINK: {
      let match;
      let lang = state.lang;
      if ((match = action.value.match(/^\S+\?(\S+)$/))) {
        const params = match[1];
        const { lang: lg } = parse(params);
        if (lg && (lg == "en" || lg == "fr")) {
          lang = lg;
        }
      }
      return {
        ...state,
        initialLink: action.value,
        lang
      };
    }

    case SET_INSTALLATION_ID:
      return {
        ...state,
        installationId: action.value
      };

    case CLEAR_INITIAL_LINK:
      return {
        ...state,
        initialLink: null
      };

    case RESET_DATA: {
      return {
        ...initialState,
        lang: state.lang,
        serverVersion: state.serverVersion,
        initialUrl: state.initialUrl,
        installationId: state.installationId
      };
    }

    case SET_GLOBAL_INFORMATIONS_VERSIONS:
    case SET_GLOBAL_INFORMATIONS: {
      const now = Date.now();
      const serverDt = action.payload.serverDateTime;
      let datetimeDiff = now - serverDt;
      if (datetimeDiff > 0 && datetimeDiff < 2000) {
        datetimeDiff = 0;
      }
      const { serverVersion, clientVersion, needupdate, forceupdate } =
        action.payload;
      return {
        ...state,
        serverVersion,
        clientVersion,
        datetimeDiff,
        needupdate,
        forceupdate
      };
    }

    case SET_PROFILE_DETAILS:
      return { ...state, accessDenied: false };

    case SET_GLOBAL_INFORMATIONS_FAILURE:
      if (
        action.payload.request?._response ===
          "Could not connect to the server." ||
        action.payload.request?._response === "The request timed out." ||
        action.payload.request?._response?.startsWith("Failed to connect to") ||
        action.payload.message === "Network Error"
      ) {
        return {
          ...state,
          dropDownAlertError: "networkerror"
        };
      }
      if (action.payload.response && action.payload.response.status === 403) {
        return { ...state, accessDenied: true };
      }
      if (action.payload.response && action.payload.response.status === 411) {
        // It's minor or major version
        return {
          ...state,
          dropDownAlertWarn:
            Platform.OS === "ios"
              ? "obsoleteversionios"
              : "obsoleteversionandroid",
          obsoleteversion: true
        };
      }
      return {
        ...state,
        dropDownAlertError: "unexpectederror"
      };

    case SET_LANG:
      return { ...state, lang: action.lang };

    case API_END: {
      switch (action.payload) {
        case FETCH_GET_GLOBAL_INFORMATIONS:
        case FETCH_GET_GLOBAL_INFORMATIONS_VERSION:
          return {
            ...state,
            isFetching: false
          };
        default:
          // Do nothing
          break;
      }
      break;
    }

    default:
      return state;
  }
  return state;
}
