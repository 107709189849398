import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation, useRoute } from "@react-navigation/core";
import "dayjs/locale/fr";
import * as Clipboard from "expo-clipboard";
import * as ImagePicker from "expo-image-picker";
import * as Location from "expo-location";
import React, { memo, useEffect, useRef, useState } from "react";
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  KeyboardAvoidingView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Avatar, Tile } from "react-native-elements";
import {
  Actions,
  Bubble,
  Composer,
  Day,
  GiftedChat,
  InputToolbar,
  LoadEarlier,
  MessageText,
  Send,
  Time,
  utils
} from "react-native-gifted-chat";
import MapView from "react-native-maps";
import { getYoutubeMeta } from "react-native-youtube-iframe";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchExitChat, fetchGetChatMessages } from "../actions/chat";
import {
  CHAT,
  CLEAR_CHAT_MAP_DATA,
  CLEAR_CHAT_PHOTO_DATA,
  CLEAR_CHAT_VIDEO_ID,
  CLEAR_READY_TO_COPY_URL,
  SET_CHAT_CURRENT_LOCATION,
  SET_CHAT_PHOTO_DATA,
  SET_CHAT_VIDEO_ID,
  SET_DROP_DOWN_ALERT_INFO,
  SET_IN_CHAT_WITH,
  SET_READY_TO_COPY_URL
} from "../actions/types";
import { getImage, getImageUri } from "../assets/Images";
import { ROLE_CAMPER } from "../config/constants";
import { getChatSelector } from "../selectors";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  isDesktop,
  isiOS,
  isWeb
} from "../styles/commonStyles";
import { openBrowser, openURL } from "../utils/UrlUtil";
import YoutubeView from "./YoutubeView";
import { v4 } from "uuid";

const { isSameUser, isSameDay } = utils;

const styles = {
  left: StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "flex-start"
    },
    wrapper: {
      borderRadius: 15,
      backgroundColor: "#f0f0f0",
      marginRight: 60,
      minHeight: 20,
      justifyContent: "flex-end"
    },
    containerToNext: {
      borderBottomLeftRadius: 3
    },
    containerToPrevious: {
      borderTopLeftRadius: 3
    },
    bottom: {
      flexDirection: "row",
      justifyContent: "flex-start"
    }
  }),
  right: StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "flex-end"
    },
    wrapper: {
      borderRadius: 15,
      backgroundColor: COLOR2,
      marginLeft: 60,
      minHeight: 20,
      justifyContent: "flex-end"
    },
    containerToNext: {
      borderBottomRightRadius: 3
    },
    containerToPrevious: {
      borderTopRightRadius: 3
    },
    bottom: {
      flexDirection: "row",
      justifyContent: "flex-end"
    }
  }),
  content: StyleSheet.create({
    tick: {
      fontSize: 10,
      backgroundColor: "transparent",
      color: "#fff"
    },
    tickView: {
      flexDirection: "row",
      marginRight: 10
    },
    username: {
      top: -3,
      left: 0,
      fontSize: 12,
      backgroundColor: "transparent",
      color: "#aaa"
    },
    usernameView: {
      flexDirection: "row",
      marginHorizontal: 10
    }
  })
};

const Chat = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();

  const giftedChat = useRef();

  const [oldContent, setOldContent] = useState("");
  const [videoMeta, setVideoMeta] = useState(null);

  let {
    user,
    lang,
    templates,
    messages,
    isFetching,
    hasNextPage,
    nextPage,
    page,
    image,
    files,
    location,
    readyToCopyUrl,
    title,
    chatTo,
    templateId,
    youtubeVideoId,
    reload
  } = useSelector(getChatSelector, shallowEqual);

  let _id, pseudo, firstname, photoUri, gender, role;
  if (user) {
    ({ _id, pseudo, firstname, photoUri, gender, role } = user);
  }

  if (messages) {
    messages = Object.values(messages);
  }

  const { to, eventId } = route.params;

  const {
    color2,
    bgColor1,
    bgColor2,
    bgColor5,
    lightgrey,
    bgWhite,
    white,
    darkgrey,
    flex1,
    justifyContentCenter,
    alignItemsCenter,
    w100p,
    cover,
    font,
    w150,
    h100,
    m10,
    rounded10,
    h44,
    overflowHidden
  } = commonStyles;

  useEffect(() => {
    if (eventId || to) {
      dispatch({
        type: SET_IN_CHAT_WITH,
        value: eventId ? eventId : to
      });
    }
    dispatch(fetchGetChatMessages(1, to, eventId, route.params.pseudo));
    return () => dispatch(fetchExitChat(to ? to : chatTo, eventId));
  }, []);

  useEffect(() => {
    if (reload) {
      dispatch(fetchGetChatMessages(1, to, eventId, route.params.pseudo));
    }
  }, [reload]);

  useEffect(() => {
    if (templateId) {
      navigation.setOptions({
        title: getTranslatedProperty(templates[templateId], "name")
      });
    } else if (title) {
      navigation.setOptions({
        title
      });
    }
  }, [templateId, title]);

  useEffect(() => {
    if (chatTo) {
      dispatch({
        type: SET_IN_CHAT_WITH,
        value: chatTo
      });
    }
  }, [chatTo]);

  useEffect(() => {
    giftedChat.current?.resetInputToolbar();
    giftedChat.current?.focusTextInput();
  }, [image, location, youtubeVideoId]);

  const sendMessage = message => {
    let computedLocation;
    if (location) {
      computedLocation = {
        latitude: location.coords.latitude,
        longitude: location.coords.longitude
      };
    }
    const msg = {
      to: to ? to : chatTo,
      eventId,
      text: message.text,
      image,
      files,
      location: computedLocation,
      youtubeVideoId
    };

    msg.meta = {
      user: {
        _id,
        name: pseudo.indexOf("@") < 0 ? pseudo : firstname,
        avatar: photoUri,
        gender
      },
      websocket: true
    };
    msg.type = CHAT;

    dispatch(msg);
    setOldContent("");
  };

  const renderSend = ({ onSend, text, sendButtonProps, ...props }) => {
    return (
      <Send
        {...props}
        textStyle={
          Boolean(!text && !image && !location && !youtubeVideoId)
            ? lightgrey
            : color2
        }
        disabled={Boolean(!text && !image && !location && !youtubeVideoId)}
        label={t("chat:send")}
        sendButtonProps={{
          ...sendButtonProps,
          onPress: () => onSend({ text: text.trim() }, true)
        }}
      />
    );
  };

  const renderAvatar = props => {
    const { avatar, name, gender } = props.currentMessage?.user;
    return (
      <Avatar
        rounded
        size={40}
        source={
          avatar
            ? { uri: avatar }
            : gender === "F"
            ? getImageUri("avatarF")
            : getImageUri("avatar")
        }
        title={name?.toUpperCase().substr(0, 2)}
        activeOpacity={0.7}
      />
    );
  };

  const LocationView = ({ location, user }) => {
    return (
      <MapView
        style={[w150, h100, rounded10, m10]}
        initialRegion={{
          latitude: location.latitude,
          longitude: location.longitude,
          latitudeDelta: 0.0043,
          longitudeDelta: 0.0034
        }}
        disabled={true}
        onPress={() => {
          openURL(
            dispatch,
            `http://maps.google.com/?q=${location.latitude},${location.longitude}&title=${user?.name}`
          );
        }}
      >
        <MapView.Marker
          coordinate={{
            latitude: location.latitude,
            longitude: location.longitude
          }}
        />
      </MapView>
    );
  };

  const renderTicks = props => {
    const { currentMessage, renderTicks, user } = props;
    if (renderTicks && currentMessage) {
      return renderTicks(currentMessage);
    }
    if (
      currentMessage &&
      user &&
      currentMessage.user &&
      currentMessage.user._id !== user._id
    ) {
      return null;
    }
    if (
      currentMessage &&
      (currentMessage.sent || currentMessage.received || currentMessage.pending)
    ) {
      return (
        <View style={styles.content.tickView}>
          {!!currentMessage.sent && (
            <Text style={[styles.content.tick, props.tickStyle]}>✓</Text>
          )}
          {!!currentMessage.received && (
            <Text style={[styles.content.tick, props.tickStyle]}>✓</Text>
          )}
          {!!currentMessage.pending && (
            <Text style={[styles.content.tick, props.tickStyle]}>🕓</Text>
          )}
        </View>
      );
    }
    return null;
  };

  const renderTime = props => {
    if (props.currentMessage && props.currentMessage.createdAt) {
      const { containerStyle, wrapperStyle, textStyle, ...timeProps } = props;
      if (props.renderTime) {
        return props.renderTime(timeProps);
      }
      return <Time {...timeProps} />;
    }
    return null;
  };

  const renderUsername = props => {
    const { currentMessage, user, renderUsernameOnMessage, usernameStyle } =
      props;
    if (renderUsernameOnMessage && currentMessage) {
      if (user && currentMessage.user._id === user._id) {
        return null;
      }
      return (
        <View style={styles.content.usernameView}>
          <Text style={[styles.content.username, usernameStyle]}>
            ~ {currentMessage.user.name}
          </Text>
        </View>
      );
    }
    return null;
  };

  const renderMessageText = props => {
    if (props.currentMessage && props.currentMessage.text) {
      const {
        containerStyle,
        wrapperStyle,
        optionTitles,
        ...messageTextProps
      } = props;
      if (props.renderMessageText) {
        return props.renderMessageText(messageTextProps);
      }
      return <MessageText {...messageTextProps} />;
    }
    return null;
  };

  const styledBubbleToNext = props => {
    const { currentMessage, nextMessage, position, containerToNextStyle } =
      props;
    if (
      currentMessage &&
      nextMessage &&
      position &&
      isSameUser(currentMessage, nextMessage) &&
      isSameDay(currentMessage, nextMessage)
    ) {
      return [
        styles[position].containerToNext,
        containerToNextStyle && containerToNextStyle[position]
      ];
    }
    return null;
  };

  const styledBubbleToPrevious = props => {
    const {
      currentMessage,
      previousMessage,
      position,
      containerToPreviousStyle
    } = props;
    if (
      currentMessage &&
      previousMessage &&
      position &&
      isSameUser(currentMessage, previousMessage) &&
      isSameDay(currentMessage, previousMessage)
    ) {
      return [
        styles[position].containerToPrevious,
        containerToPreviousStyle && containerToPreviousStyle[position]
      ];
    }
    return null;
  };

  const renderBubble = props => {
    const {
      currentMessage,
      position,
      containerStyle,
      wrapperStyle,
      bottomContainerStyle
    } = props;
    if (currentMessage.location || currentMessage.youtubeVideoId) {
      return (
        <View
          style={[
            styles[position].container,
            containerStyle && containerStyle[position]
          ]}
        >
          <View
            style={[
              styles[position].wrapper,
              styledBubbleToNext(props),
              styledBubbleToPrevious(props),
              wrapperStyle && wrapperStyle[position]
            ]}
          >
            <TouchableOpacity
              onPress={() => {
                if (currentMessage.youtubeVideoId) {
                  navigation.navigate("YoutubeScreen", {
                    videoId: currentMessage.youtubeVideoId
                  });
                }
              }}
              disabled={currentMessage.location}
            >
              {currentMessage.location ? (
                <LocationView
                  location={currentMessage.location}
                  user={currentMessage.user}
                />
              ) : (
                <YoutubeView youtubeVideoId={currentMessage.youtubeVideoId} />
              )}
              {renderMessageText(props)}
              <View
                style={[
                  styles[position].bottom,
                  bottomContainerStyle && bottomContainerStyle[position]
                ]}
              >
                {renderUsername(props)}
                {renderTime(props)}
                {renderTicks(props)}
              </View>
            </TouchableOpacity>
          </View>
        </View>
      );
    }
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          left: bgColor5,
          right: bgColor2
        }}
        textStyle={{
          left: [white, font],
          right: [white, font]
        }}
      />
    );
  };

  const renderDay = props => {
    return <Day {...props} textStyle={darkgrey} />;
  };

  const renderMessageImage = props => {
    return (
      <Avatar
        containerStyle={[w150, h100, rounded10, m10]}
        renderPlaceholderContent={
          <Image
            style={[w150, h100, rounded10]}
            source={getImageUri("placeholder")}
          />
        }
        source={{
          uri: props.currentMessage.image
        }}
        overlayContainerStyle={[rounded10]}
        onPress={() => {
          navigation.navigate("ImageZoomScreen", {
            uri: props.currentMessage.image
          });
        }}
      />
    );
  };

  const renderLoadEarlier = props => {
    return (
      hasNextPage && <LoadEarlier {...props} label={t("chat:loadearlier")} />
    );
  };

  const _pickImage = async () => {
    if (!isWeb) {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        dispatch({
          type: SET_DROP_DOWN_ALERT_INFO,
          info: "photosinfo"
        });
        return;
      }
    }
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: "Images",
      quality: 0.1,
      base64: true
    });

    if (!result.cancelled) {
      if (isWeb) {
        const files = [];
        const name = "" + Date.now();
        files.push({
          name,
          base64: result.uri
        });
        dispatch({
          type: SET_CHAT_PHOTO_DATA,
          value: result.uri,
          files
        });
      } else {
        const files = [];
        const filename = result.uri.split("/").pop();
        const name = filename
          ? filename.substring(0, filename.lastIndexOf("."))
          : "" + Date.now();
        files.push({
          name,
          base64: "data:image/jpeg;base64," + result.base64
        });
        dispatch({
          type: SET_CHAT_PHOTO_DATA,
          value: result.uri,
          files
        });
      }
    }
  };

  const handleOnChangeText = async content => {
    const regEnd = /^[-_a-zA-Z0-9][\n.!, ]$/;
    if (
      content === "" ||
      image ||
      location ||
      youtubeVideoId ||
      oldContent.length > content.length ||
      content.length < 8 ||
      !regEnd.test(content.substring(content.length - 2))
    ) {
      setOldContent(content);
      return;
    }
    setOldContent(content);
    let match;
    if (
      (match = content.match(
        /^.*((http:\/\/|https:\/\/|www.)\S+?\.(?:jpe?g|gif|png|tiff|svg))[\n.!, ]$/
      ))
    ) {
      dispatch({
        type: SET_CHAT_PHOTO_DATA,
        value: match[1]
      });
    }
    if (
      (match = content.match(
        /^.*(https:\/\/www.|https:\/\/)youtube.com\/watch\?v=([a-zA-Z0-9]+)(\&[a-zA-Z0-9=]+)?[\n.!, ]$/
      )) ||
      (match = content.match(
        /^.*(https:\/\/www.|https:\/\/)youtu.be\/([-_a-zA-Z0-9]+)(\?\S+=\S+)?[\n.!, ]$/
      ))
    ) {
      dispatch({
        type: SET_CHAT_VIDEO_ID,
        value: match[2]
      });
      setVideoMeta(await getYoutubeMeta(match[2]));
    }
  };

  const renderInputToolbar = props => {
    return (
      <View style={[flex1, bgWhite]}>
        {videoMeta && (
          <View style={{ minHeight: 60 }}>
            <Tile
              activeOpacity={0.5}
              containerStyle={[w150, h100, rounded10, m10, overflowHidden]}
              imageSrc={{ uri: videoMeta.thumbnail_url }}
              imageContainerStyle={[rounded10, w150, h100]}
              icon={{
                name: "play-circle",
                type: "font-awesome",
                size: 30,
                color: COLOR2
              }}
              onPress={() => {
                dispatch({ type: CLEAR_CHAT_VIDEO_ID });
                setVideoMeta(null);
              }}
              featured
            />
            <MaterialCommunityIcons
              style={{ position: "absolute", top: 10, right: 10 }}
              name="delete-forever"
              color="red"
              size={20}
            />
          </View>
        )}
        {location && (
          <View style={{ minHeight: 60 }}>
            <MapView
              style={[w150, h100, rounded10, m10]}
              initialRegion={{
                latitude: location.coords.latitude,
                longitude: location.coords.longitude,
                latitudeDelta: 0.0043,
                longitudeDelta: 0.0034
              }}
              scrollEnabled={false}
              zoomEnabled={false}
            >
              <MapView.Marker
                coordinate={{
                  latitude: location.coords.latitude,
                  longitude: location.coords.longitude
                }}
              />
              <MaterialCommunityIcons
                style={{ position: "absolute", top: 10, right: 10 }}
                name="delete-forever"
                color="red"
                size={20}
                onPress={() =>
                  dispatch({
                    type: CLEAR_CHAT_MAP_DATA
                  })
                }
              />
            </MapView>
          </View>
        )}
        {image && (
          <View style={{ minHeight: 60 }}>
            <Avatar
              containerStyle={[w150, h100, rounded10, m10]}
              // placeholderStyle={[rounded10]}
              renderPlaceholderContent={
                <Image
                  style={[w150, h100, rounded10]}
                  source={getImageUri("placeholder")}
                />
              }
              source={{
                uri: image
              }}
              overlayContainerStyle={[rounded10]}
              onPress={() => {
                dispatch({
                  type: CLEAR_CHAT_PHOTO_DATA
                });
              }}
            >
              <MaterialCommunityIcons
                style={{ position: "absolute", top: 10, right: 10 }}
                name="delete-forever"
                color="red"
                size={20}
              />
            </Avatar>
          </View>
        )}
        <InputToolbar {...props} />
      </View>
    );
  };

  const renderComposer = props => {
    return (
      <Composer
        {...props}
        textInputProps={{
          ...props.textInputProps
          // value: oldContent
        }}
        onTextChanged={text => {
          props.onTextChanged(text);
          handleOnChangeText(text);
        }}
      />
    );
  };

  const _currentLocation = async () => {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      dispatch({
        type: SET_DROP_DOWN_ALERT_INFO,
        info: "locationinfo"
      });
      return;
    }

    const location = await Location.getCurrentPositionAsync({
      accuracy: isiOS ? Location.Accuracy.Lowest : Location.Accuracy.Low
    });
    dispatch({
      type: SET_CHAT_CURRENT_LOCATION,
      location
    });
  };

  const renderActions = props => {
    return (
      role !== ROLE_CAMPER && (
        <Actions
          {...props}
          containerStyle={[
            h44,
            alignItemsCenter,
            justifyContentCenter,
            { marginHorizontal: 4, marginBottom: 0 }
          ]}
          icon={() => (
            <Image
              style={{ width: 32, height: 32 }}
              source={getImage("plus")}
            />
          )}
          options={
            isDesktop
              ? {
                  [t("button:copygiphy")]: () => {
                    dispatch({ type: SET_READY_TO_COPY_URL });
                    openBrowser(dispatch, "https://giphy.com/search/camping");
                  },
                  [t("button:copyyoutube")]: () => {
                    dispatch({ type: SET_READY_TO_COPY_URL });
                    openBrowser(dispatch, "https://www.youtube.com");
                  },
                  [t("button:shareposition")]: _currentLocation,
                  [t("button:camera")]: () => {
                    navigation.navigate("CameraScreen", {
                      from: "chat"
                    });
                  },
                  [t("button:albums")]: _pickImage,
                  [t("button:cancel")]: () => {}
                }
              : {
                  [t("button:shareposition")]: _currentLocation,
                  [t("button:camera")]: () => {
                    navigation.navigate("CameraScreen", {
                      from: "chat"
                    });
                  },
                  [t("button:albums")]: _pickImage,
                  [t("button:cancel")]: () => {}
                }
          }
          optionTintColor={COLOR2}
        />
      )
    );
  };

  return (
    <KeyboardAvoidingView style={[flex1, bgColor1]}>
      <ImageBackground
        source={getImageUri("tent")}
        style={[w100p, flex1, cover]}
      >
        <View style={[flex1, { backgroundColor: "rgba(255,255,255,0.5)" }]}>
          {isFetching && page === 1 ? (
            <ActivityIndicator
              style={[flex1, justifyContentCenter]}
              size="large"
              color={COLOR2}
            />
          ) : (
            <GiftedChat
              ref={giftedChat}
              disabled={!to && !pseudo && !eventId}
              messageIdGenerator={isWeb ? v4 : undefined}
              messages={messages}
              onSend={messages => sendMessage(messages[0])}
              user={{
                _id,
                name: pseudo,
                avatar: photoUri,
                gender
              }}
              placeholder={t("chat:placeholder")}
              showUserAvatar={true}
              renderUsernameOnMessage={Boolean(eventId)}
              showAvatarForEveryMessage={true}
              renderAvatar={renderAvatar}
              renderBubble={renderBubble}
              renderDay={renderDay}
              renderMessageImage={renderMessageImage}
              renderComposer={renderComposer}
              locale={lang}
              infiniteScroll={true}
              text={oldContent}
              onLoadEarlier={() =>
                hasNextPage &&
                dispatch(
                  fetchGetChatMessages(
                    nextPage,
                    to,
                    eventId,
                    route.params.pseudo
                  )
                )
              }
              isLoadingEarlier={isFetching}
              loadEarlier={true}
              renderLoadEarlier={renderLoadEarlier}
              renderInputToolbar={renderInputToolbar}
              renderSend={renderSend}
              minInputToolbarHeight={
                Boolean(image || location || youtubeVideoId) ? 170 : 44
              }
              alwaysShowSend={true}
              renderActions={renderActions}
              textInputProps={{
                onFocus: async () => {
                  if (readyToCopyUrl) {
                    try {
                      if (isDesktop) {
                        const text = await Clipboard.getStringAsync();
                        const re =
                          /^((http:\/\/|https:\/\/|www.)\S+?\.(?:jpe?g|gif|png|tiff|svg))$/;
                        if (re.test(text)) {
                          dispatch({
                            type: SET_CHAT_PHOTO_DATA,
                            value: text
                          });
                        }
                        let match;
                        if (
                          (match = text.match(
                            /^(https:\/\/www.|https:\/\/)youtube.com\/watch\?v=([a-zA-Z0-9]+)(\&[a-zA-Z0-9=]+)?$/
                          )) ||
                          (match = text.match(
                            /^(https:\/\/www.|https:\/\/)youtu.be\/([-_a-zA-Z0-9]+)(\?\S+=\S+)?$/
                          ))
                        ) {
                          dispatch({
                            type: SET_CHAT_VIDEO_ID,
                            value: match[2]
                          });
                          setVideoMeta(await getYoutubeMeta(match[2]));
                        }
                      }
                    } catch (error) {
                      console.error(error);
                    } finally {
                      dispatch({ type: CLEAR_READY_TO_COPY_URL });
                    }
                  }
                }
              }}
            />
          )}
        </View>
      </ImageBackground>
    </KeyboardAvoidingView>
  );
};

const MemoizedChat = memo(Chat);
export default MemoizedChat;
