import {
  Entypo,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons
} from "@expo/vector-icons";
import AppLoading from "expo-app-loading";
import { Asset } from "expo-asset";
import * as Font from "expo-font";
import * as Localization from "expo-localization";
import * as ScreenOrientation from "expo-screen-orientation";
import React, { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { Image, LogBox, StatusBar, Text, UIManager, View } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { enableScreens } from "react-native-screens";
import { Provider } from "react-redux";
import { initExpoBranch } from "./BranchIO";
import Sentry from "./sentry";
import { fetchGetCachedImages } from "./src/actions";
import { SET_LANG } from "./src/actions/types";
import { Images, setCachedImages } from "./src/assets/Images";
import { SENTRY_DSN } from "./src/config/constants";
import * as config from "./src/config/i18n";
import { fetch } from "./src/middleware/api";
import AppNavigationContainer from "./src/navigation/AppNavigationContainer";
import i18n from "./src/services/i18n";
import configureStore from "./src/store/configure-store";
import commonStyles, {
  FONT,
  FONT_BOLD,
  FONT_LIGHT,
  isAndroid,
  isElectron,
  isWeb
} from "./src/styles/commonStyles";
import isDevelopment from "./src/utils/electron-is-dev";

if (!isElectron && __DEV__) {
  import("./ReactotronConfig").then(() => console.log("Reactotron Configured"));
}

//Optimize memory usage and performance
enableScreens();

if (isWeb) {
  // Add Google maps api
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${
    __DEV__ || (isElectron && isDevelopment)
      ? "AIzaSyCULbeeNoYJep_Qzb_9nmf_vJkzA467Oeg"
      : "AIzaSyDoTNc45o-X3V5iSYUqjKgr2tkv4Wh_9sc"
  }`;
  script.async = true;
  document.body.appendChild(script);
  const base = document.createElement("base");
  base.href = "./";
  document.head.appendChild(base);
}

StatusBar.setBarStyle("light-content");
if (isAndroid) {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

LogBox.ignoreLogs([
  "VirtualizedLists should never be nested",
  "Warning: componentWillReceiveProps has been renamed",
  "Warning: componentWillMount has been renamed"
]);

const store = configureStore();

function cacheImages(images) {
  return images.map(image => {
    if (typeof image === "string") {
      return Image.prefetch(image);
    } else {
      return Asset.fromModule(
        isElectron ? image.default : image
      ).downloadAsync();
    }
  });
}

function cacheFonts(fonts) {
  return fonts.map(font => Font.loadAsync(font));
}

Sentry.init({
  dsn: SENTRY_DSN,
  enableInExpoDevelopment: false,
  debug: false,
  enableNative: false
});

async function _loadAssetsAsync() {
  await i18n.init();

  // Disable font scaling for Android
  Text.defaultProps = Text.defaultProps || {};
  Text.defaultProps.allowFontScaling = false;

  // Branch IO init
  await initExpoBranch();

  // Init firebase
  if (isWeb && !isElectron && !isIOS) {
    require("./firebase/config").firebaseInit();
  }

  let cachedImages;
  // Retrieve cached images
  try {
    cachedImages = await fetch(fetchGetCachedImages());
    setCachedImages(cachedImages);
  } catch (error) {
    console.error(error);
  }

  const lng = await Localization.getLocalizationAsync();
  store.dispatch({
    type: SET_LANG,
    lang: config.supportedLocales[i18n.locale] ? i18n.locale : config.fallback,
    country: lng && lng.region ? lng.region : "FR"
  });

  const imageAssets = cacheImages(Object.values(Images));

  const fontAssets = cacheFonts([
    {
      fontBold: isElectron ? FONT_BOLD.default : FONT_BOLD,
      fontLight: isElectron ? FONT_LIGHT.default : FONT_LIGHT,
      font: isElectron ? FONT.default : FONT
    },
    FontAwesome.font,
    MaterialCommunityIcons.font,
    MaterialIcons.font,
    Ionicons.font,
    Entypo.font
  ]);
  await Promise.all([...imageAssets, ...fontAssets]);
}

export default function App() {
  const [isReady, setReady] = useState(false);
  const { flex1, content } = commonStyles;
  const [orientation, setOrientation] = useState(
    ScreenOrientation.Orientation.PORTRAIT_UP
  );

  const changeOrientation = orientation => {
    setOrientation(orientation);
  };

  useEffect(() => {
    ScreenOrientation.addOrientationChangeListener(changeOrientation);
    return () => {
      ScreenOrientation.removeOrientationChangeListeners(changeOrientation);
    };
  }, []);

  if (!isReady) {
    return (
      <AppLoading
        startAsync={_loadAssetsAsync}
        onFinish={() => setReady(true)}
        onError={console.warn}
        autoHideSplash={false}
      />
    );
  }

  return (
    <View style={[flex1, { backgroundColor: "rgba(0,0,0,0.3)" }]}>
      <SafeAreaProvider
        style={
          orientation === ScreenOrientation.Orientation.PORTRAIT_UP && content
        }
      >
        <Provider store={store}>
          <AppNavigationContainer />
        </Provider>
      </SafeAreaProvider>
    </View>
  );
}
