import { Dimensions, Platform, StyleSheet } from "react-native";
import * as Device from "expo-device";

const window = Dimensions.get("window");
export const { width, height: SCREEN_HEIGHT, scale: SCALE } = window;
// on web we limit the max width
export const SCREEN_WIDTH = Math.min(width, 550);

export const isAndroid = Platform.OS === "android";
export const isiOS = Platform.OS === "ios";
export const isWeb = Platform.OS === "web";
export const isNative = isAndroid || isiOS;

export const isSafari =
  isWeb &&
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") == -1 &&
  navigator.userAgent.indexOf("FxiOS") == -1;
export const isElectron = Boolean(
  process &&
    process.versions !== undefined &&
    !!("electron" in process.versions)
);

export let isPhone = false;
export let isTablet = false;
export let isDesktop = false;

export let deviceType;
(async () => {
  deviceType = await Device.getDeviceTypeAsync();
  isPhone = deviceType === Device.DeviceType.PHONE;
  isTablet = deviceType === Device.DeviceType.TABLET;
  isDesktop = deviceType === Device.DeviceType.DESKTOP;
})();

export const FONT_BOLD = require("../../src/assets/fonts/GothamRnd-Bold.otf");
export const FONT_LIGHT = require("../../src/assets/fonts/GothamRnd-Light.otf");
export const FONT = require("../../src/assets/fonts/GothamRnd-Medium.otf");

export const COLOR1 = "#f1f1f1";
export const COLOR2 = "#e99229";
export const COLOR3 = "#49accd";
export const COLOR4 = "#ba986b";
export const COLOR5 = "#53656F";

export const WHITE_COLOR = "#fff";
export const FB_COLOR = "#4267B2";
export const ORANGE_COLOR = "#e99229";
export const BROWN_COLOR = "#ba986b";
export const BLUE_COLOR = "#49accd";
export const PURPLE_COLOR = "#6441a4";
export const PINK_COLOR = "#ff1493";
export const GREEN_COLOR = "#73b74a";
export const DARKGREY_COLOR = "#444041";
export const LIGHTLIGHTGREY_COLOR = "#fafafa";

export default StyleSheet.create({
  /* Color */
  color1: {
    color: COLOR1
  },
  color2: {
    color: COLOR2
  },
  color3: {
    color: COLOR3
  },
  color4: {
    color: COLOR4
  },
  color5: {
    color: COLOR5
  },
  red: {
    color: "red"
  },
  pink: {
    color: PINK_COLOR
  },
  lightblue: {
    color: "#b4d6df"
  },
  lightgrey: {
    color: "#f1f1f1"
  },
  green: {
    color: GREEN_COLOR
  },
  white: {
    color: "white"
  },
  black: {
    color: "black"
  },
  orange: {
    color: ORANGE_COLOR
  },
  darkgrey: {
    color: DARKGREY_COLOR
  },
  /* Background color */
  bgColor1: {
    backgroundColor: COLOR1
  },
  bgColor2: {
    backgroundColor: COLOR2
  },
  bgColor3: {
    backgroundColor: COLOR3
  },
  bgColor4: {
    backgroundColor: COLOR4
  },
  bgColor5: {
    backgroundColor: COLOR5
  },
  bgTransparent: {
    backgroundColor: "transparent"
  },
  bgOrange: {
    backgroundColor: ORANGE_COLOR
  },
  bgGreen: {
    backgroundColor: GREEN_COLOR
  },
  bgPink: {
    backgroundColor: PINK_COLOR
  },
  bgPurple: {
    backgroundColor: PURPLE_COLOR
  },
  bgLightblue: {
    backgroundColor: "#b4d6df"
  },
  bgLightred: {
    backgroundColor: "#ff9494"
  },
  bgLightgrey: {
    backgroundColor: "#f1f1f1"
  },
  bgLightLightgrey: {
    backgroundColor: LIGHTLIGHTGREY_COLOR
  },
  bgWhite: {
    backgroundColor: "white"
  },
  bgBlack: {
    backgroundColor: "black"
  },
  bgDarkgrey: {
    backgroundColor: DARKGREY_COLOR
  },
  /* Flex */
  row: {
    flexDirection: "row"
  },
  rowReverse: {
    flexDirection: "row-reverse"
  },
  columnReverse: {
    flexDirection: "column-reverse"
  },
  flex0: {
    flex: 0
  },
  flex1: {
    flex: 1
  },
  flex2: {
    flex: 2
  },
  flex3: {
    flex: 3
  },
  flex4: {
    flex: 4
  },
  flexWrap: {
    flexWrap: "wrap"
  },
  spaceAround: {
    justifyContent: "space-around"
  },
  spaceBetween: {
    justifyContent: "space-between"
  },
  justifyContentCenter: {
    justifyContent: "center"
  },
  justifyContentFlexEnd: {
    justifyContent: "flex-end"
  },
  justifyContentSpaceEvenly: {
    justifyContent: "space-evenly"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  alignItemsFlexEnd: {
    alignItems: "flex-end"
  },
  /* Margin */
  m20: {
    margin: 20
  },
  m10: {
    margin: 10
  },
  mr5: {
    marginRight: 5
  },
  mt5: {
    marginTop: 5
  },
  mt10: {
    marginTop: 10
  },
  mt20: {
    marginTop: 20
  },
  ml5: {
    marginLeft: 5
  },
  ml10: {
    marginLeft: 10
  },
  ml20: {
    marginLeft: 20
  },
  mr10: {
    marginRight: 10
  },
  mb10: {
    marginBottom: 10
  },
  mb5: {
    marginBottom: 5
  },
  mb20: {
    marginBottom: 20
  },
  mb40: {
    marginBottom: 40
  },
  mv10: {
    marginVertical: 10
  },
  mv20: {
    marginVertical: 20
  },
  mv5: {
    marginVertical: 5
  },
  mh10: {
    marginHorizontal: 10
  },
  mh20: {
    marginHorizontal: 20
  },
  /* Padding */
  p5: {
    padding: 5
  },
  p10: {
    padding: 10
  },
  p20: {
    padding: 20
  },
  p40: {
    padding: 40
  },
  pt10: {
    paddingTop: 10
  },
  pb10: {
    paddingBottom: 10
  },
  pb20: {
    paddingBottom: 20
  },
  pb50: {
    paddingBottom: 50
  },
  pr5: {
    paddingRight: 5
  },
  pr10: {
    paddingRight: 10
  },
  pv10: {
    paddingVertical: 10
  },
  pv20: {
    paddingVertical: 20
  },
  ph3: {
    paddingHorizontal: 3
  },
  ph10: {
    paddingHorizontal: 10
  },
  ph20: {
    paddingHorizontal: 20
  },
  ph40: {
    paddingHorizontal: 40
  },
  /* Fonts */
  font: {
    fontFamily: "font"
  },
  fontBold: {
    fontFamily: "fontBold"
  },
  fontLight: {
    fontFamily: "fontLight"
  },
  bold: {
    fontWeight: "bold"
  },
  underline: {
    textDecorationLine: "underline"
  },
  uppercase: {
    textTransform: "uppercase"
  },
  fs10: {
    fontSize: 10
  },
  fs12: {
    fontSize: 12
  },
  fs14: {
    fontSize: 14
  },
  fs16: {
    fontSize: 16
  },
  fs18: {
    fontSize: 18
  },
  fs20: {
    fontSize: 20
  },
  fs25: {
    fontSize: 25
  },
  fs40: {
    fontSize: 40
  },
  /* Border */
  rounded30: {
    borderRadius: 30
  },
  rounded22: {
    borderRadius: 22
  },
  roundedTop22: {
    borderTopLeftRadius: 22,
    borderTopRightRadius: 22
  },
  roundedBottom22: {
    borderBottomLeftRadius: 22,
    borderBottomRightRadius: 22
  },
  roundedBottom30: {
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30
  },
  rounded10: {
    borderRadius: 10
  },
  rounded5: {
    borderRadius: 5
  },
  roundedTop5: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  },
  borderBrown: {
    borderColor: COLOR4,
    borderWidth: 1
  },
  borderColor2: {
    borderColor: COLOR2,
    borderWidth: 1
  },
  borderBrown2: {
    borderColor: COLOR4,
    borderWidth: 2
  },
  borderOrange2: {
    borderColor: COLOR2,
    borderWidth: 2
  },
  borderDarkgrey: {
    borderColor: "#444041",
    borderWidth: 1
  },
  borderBlack: {
    borderColor: "black",
    borderWidth: 1
  },
  borderRed: {
    borderColor: "red",
    borderWidth: 1
  },
  borderBlue: {
    borderColor: COLOR3,
    borderWidth: 1
  },
  borderBlue2: {
    borderColor: COLOR3,
    borderWidth: 2
  },
  borderBottomColor5: {
    borderBottomColor: COLOR5,
    borderBottomWidth: 1
  },
  borderRightColor5: {
    borderRightColor: COLOR5,
    borderRightWidth: 1
  },
  borderBottomBlack: {
    borderBottomColor: "black",
    borderBottomWidth: 1
  },
  borderTopBlack: {
    borderTopColor: "black",
    borderTopWidth: 1
  },
  borderRightBlack: {
    borderRightColor: "black",
    borderRightWidth: 1
  },
  borderLeftBlack: {
    borderLeftColor: "black",
    borderLeftWidth: 1
  },
  borderBottomWhite: {
    borderBottomColor: "white",
    borderBottomWidth: 1
  },
  borderBottomLightblue: {
    borderBottomColor: "#b4d6df",
    borderBottomWidth: 1
  },
  /* Image */
  cover: {
    resizeMode: "cover"
  },
  contain: {
    resizeMode: "contain"
  },
  /* Others */
  textCenter: {
    textAlign: "center"
  },
  h44: {
    // Button/TextInput recommended minimum size
    height: 44
  },
  h40: {
    height: 40
  },
  h100: {
    height: 100
  },
  h200: {
    height: 200
  },
  h100p: {
    height: "100%"
  },
  w40: {
    width: 40
  },
  w44: {
    width: 44
  },
  h60: {
    height: 60
  },
  w60: {
    width: 60
  },
  h80: {
    height: 80
  },
  w80: {
    width: 80
  },
  w100: {
    width: 100
  },
  w100p: {
    width: "100%"
  },
  w120: {
    width: 120
  },
  w150: {
    width: 150
  },
  w200: {
    width: 200
  },
  none: {
    display: "none"
  },
  opacity07: {
    opacity: 0.7
  },
  opacity05: {
    opacity: 0.5
  },
  opacity1: {
    opacity: 1
  },
  opacity0: {
    opacity: 0
  },
  // Shadow
  shadowGrey: {
    elevation: 5,
    shadowColor: DARKGREY_COLOR,
    shadowOpacity: 2,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 }
  },
  // Position
  positionAbsolute: {
    position: "absolute"
  },
  zindex1: {
    zIndex: 1000
  },
  overflowHidden: {
    overflow: "hidden"
  },
  content: {
    width: SCREEN_WIDTH,
    height: SCREEN_WIDTH,
    marginLeft: "auto",
    marginRight: "auto"
  }
});
