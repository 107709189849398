import { FontAwesome } from "@expo/vector-icons";
import React, { useEffect, useRef } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Text, TouchableOpacity } from "react-native";
import { Tooltip } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginWithFB, fetchSignUpWithFB } from "../actions/login";
import {
  CLEAR_VALIDATE_FB,
  SET_DROP_DOWN_ALERT_ERROR,
  SET_LOGIN_WITH_FB_RESULT,
  SET_MISSING_EMAIL_ERROR,
  SET_TOOLTIP6_VISIBLE,
  VALIDATE_FB_INPUTS
} from "../actions/types";
import { t } from "../services/i18n";
import commonStyles, { DARKGREY_COLOR, FB_COLOR } from "../styles/commonStyles";

export default function FBLogin() {
  const dispatch = useDispatch();

  const tooltip5Ref = useRef();

  const {
    emailOrPseudo,
    registerkey,
    clearValidateFB,
    validFB,
    selectedCategory,
    isTooltip5Visible,
    onClick
  } = useSelector(state => state.loginReducer);
  const { lang } = useSelector(state => state.globalInformationsReducer);
  const { currentCommunity } = useSelector(state => state.communityReducer);
  const communityId = currentCommunity?._id;

  const responseFacebook = response => {
    if (!response || !response.accessToken) {
      dispatch({
        type: SET_DROP_DOWN_ALERT_ERROR,
        error: "facebookerror"
      });
      return;
    }

    if (!response.email && !emailOrPseudo && selectedCategory) {
      // Facebook email is not provided
      // We stop process and display an error message
      dispatch({
        type: SET_MISSING_EMAIL_ERROR
      });
      return;
    }

    dispatch({
      type: SET_LOGIN_WITH_FB_RESULT,
      data: response
    });
    if (selectedCategory) {
      dispatch(
        fetchSignUpWithFB(
          communityId,
          response.accessToken,
          registerkey,
          response.email ? response.email : emailOrPseudo,
          lang
        )
      );
    } else {
      dispatch(fetchLoginWithFB(communityId, response.accessToken));
    }
  };

  useEffect(() => {
    if (clearValidateFB) {
      dispatch({
        type: VALIDATE_FB_INPUTS
      });
    }
  }, [clearValidateFB]);

  useEffect(() => {
    if (validFB && onClick) {
      onClick();
    }
  }, [validFB]);

  useEffect(() => {
    if (isTooltip5Visible) {
      tooltip5Ref?.current?.toggleTooltip();
    }
  }, [isTooltip5Visible]);

  const { justifyContentCenter, alignItemsCenter, font, white } = commonStyles;

  return (
    <Tooltip
      ref={tooltip5Ref}
      popover={<Text style={[font, white]}>{t("login:tooltip5")}</Text>}
      backgroundColor={DARKGREY_COLOR}
      toggleOnPress={false}
      withOverlay={true}
      height={80}
      width={180}
      onClose={() =>
        setTimeout(
          () =>
            dispatch({
              type: SET_TOOLTIP6_VISIBLE,
              payload: true
            }),
          10
        )
      }
      skipAndroidStatusBar
    >
      <FacebookLogin
        appId={"558220515241025"}
        callback={responseFacebook}
        redirectUri={"https://www.facebook.com/connect/login_success.html"}
        fields="first_name,last_name,email,picture.type(large)"
        render={renderProps => (
          <TouchableOpacity
            style={[
              {
                width: 54,
                height: 54,
                borderRadius: 27,
                backgroundColor: FB_COLOR,
                marginHorizontal: 10
              },
              alignItemsCenter,
              justifyContentCenter
            ]}
            onPress={() =>
              dispatch({
                type: CLEAR_VALIDATE_FB,
                onClick: renderProps.onClick
              })
            }
          >
            <FontAwesome name="facebook" size={26} color="white" />
          </TouchableOpacity>
        )}
        disableMobileRedirect={true}
      />
    </Tooltip>
  );
}
