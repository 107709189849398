import {
  API_START,
  LOGIN_FAILURE,
  API_END,
  FETCH_LOGIN,
  LOGOUT_RESULT,
  FETCH_SIGNUP,
  SET_EMAIL_OR_PSEUDO,
  SET_PASSWORD,
  VALIDATE_INPUTS,
  CLEAR_VALIDATE,
  SET_SELECTED_CATEGORY,
  SET_REGISTER_KEY,
  FETCH_FORGET_PASSWORD,
  DELETE_USER_RESULT,
  SET_LOGIN_WITH_FB_RESULT,
  VALIDATE_FB_INPUTS,
  CLEAR_VALIDATE_FB,
  FETCH_SIGNUP_WITH_FB,
  FETCH_LOGIN_WITH_FB,
  FETCH_GET_USER,
  FETCH_GET_LOCATIONS,
  FETCH_GET_TEMPLATES,
  FETCH_GET_TEMPLATE_DATES,
  FETCH_GET_TEMPLATE_TIMES,
  FETCH_GET_ADS_CATEGORIES,
  FETCH_GET_HOBBIES,
  FETCH_GET_MY_CHAT_USERS,
  FETCH_GET_CURRENT_COMMUNITY,
  FETCH_GET_GLOBAL_INFORMATIONS,
  CLEAR_FORGET_PWD_VALIDATE,
  VALIDATE_FORGET_PWD_INPUT,
  CHANGE_CUSTOMER_TARGET,
  FETCH_LOGOUT,
  LOGOUT,
  CLEAR_LOGOUT,
  SET_DEEP_LINK_TOKEN,
  SET_PROFILE_DETAILS,
  SET_GLOBAL_INFORMATIONS_FAILURE,
  REGISTER_FOR_PUSH_NOTIF,
  CLEAR_REGISTER_PUSH_NOTIFICATIONS,
  RESET_DATA,
  FETCH_LOGIN_USING_TOKEN,
  FETCH_LOGIN_WITH_APPLE,
  FETCH_SIGNUP_WITH_APPLE,
  VALIDATE_APPLE_INPUTS,
  CLEAR_VALIDATE_APPLE,
  NAVIGATION,
  RESET_AND_SUBSCRIBE,
  CLEAR_VALIDATE_GUEST,
  VALIDATE_GUEST_INPUT,
  FETCH_LOGIN_AS_GUEST,
  FETCH_SIGNUP_AS_GUEST,
  FETCH_GET_GLOBAL_INFORMATIONS_FROM_LOGIN,
  SET_TOOLTIP3_VISIBLE,
  SET_TOOLTIP4_VISIBLE,
  SET_TOOLTIP5_VISIBLE,
  SET_TOOLTIP6_VISIBLE,
  SET_INITIAL_URL,
  SET_MISSING_EMAIL_ERROR,
  CLEAR_LOGIN,
  SET_COMMUNITY_DETAILS
} from "../actions/types";
import { Record } from "immutable";
import { t } from "../services/i18n";
import { parse } from "search-params";
import { ROLE_CAMPER } from "../../src/config/constants";

function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(email);
}

const InitialState = new Record({
  isFetching: false,
  areFetching: {},
  errors: {},
  emailOrPseudo: "",
  emailOrPseudoErrorMsg: null,
  password: "",
  passwordErrorMsg: null,
  deleteUserResult: null,
  clearValidate: false,
  valid: false,
  selectedCategory: 0,
  registerkey: null,
  registerkeyErrorMsg: null,
  clearValidateFB: false,
  clearValidateApple: false,
  validFB: false,
  validApple: false,
  expires: null,
  permissions: null,
  declinedPermissions: null,
  profileData: null,
  clearForgetPwdValidate: false,
  validForgetPwd: false,
  selectedIndex: 0,
  scroll: true,
  logout: null,
  logoutResult: null,
  routeName: "LandingScreen",
  accessDenied: false,
  showLoginModal: false,
  registerForPushNotifications: false,
  needRegisterKey: false,
  token: null,
  preparetoken: null,
  tokenErrorMsg: null,
  hideRegisterKey: false,
  subscribe: false,
  validSignupGuest: false,
  clearValidateSignupGuest: false,
  isTooltip3Visible: false,
  isTooltip4Visible: false,
  isTooltip5Visible: false,
  isTooltip6Visible: false,
  fromLogin: false,
  onClick: null
});

const initialState = new InitialState();
export default function loginReducer(state = initialState, action = {}) {
  switch (action.type) {
    case API_START: {
      switch (action.payload) {
        case FETCH_LOGIN:
        case FETCH_LOGIN_USING_TOKEN:
        case FETCH_SIGNUP:
        case FETCH_LOGIN_WITH_FB:
        case FETCH_LOGIN_WITH_APPLE:
        case FETCH_FORGET_PASSWORD:
        case FETCH_SIGNUP_WITH_FB:
        case FETCH_SIGNUP_WITH_APPLE:
        case FETCH_LOGOUT:
        case FETCH_SIGNUP_AS_GUEST:
        case FETCH_LOGIN_AS_GUEST:
          return state
            .set("isFetching", true)
            .set("logoutResult", null)
            .set("tokenErrorMsg", null)
            .set("errors", {});

        case FETCH_GET_GLOBAL_INFORMATIONS_FROM_LOGIN:
          return state
            .set("fromLogin", true)
            .setIn(["areFetching", "isFetchingGetGlobalInformations"], true);

        case FETCH_GET_GLOBAL_INFORMATIONS:
          return state
            .set("fromLogin", false)
            .setIn(["areFetching", "isFetchingGetGlobalInformations"], true);

        case FETCH_GET_USER:
          return state.setIn(["areFetching", "isFetchingGetUser"], true);

        case FETCH_GET_LOCATIONS:
          return state.setIn(["areFetching", "isFetchingGetLocations"], true);

        case FETCH_GET_TEMPLATES:
          return state.setIn(["areFetching", "isFetchingGetTemplates"], true);

        case FETCH_GET_TEMPLATE_DATES:
          return state.setIn(
            ["areFetching", "isFetchingGetTemplateDates"],
            true
          );

        case FETCH_GET_TEMPLATE_TIMES:
          return state.setIn(
            ["areFetching", "isFetchingGetTemplateTimes"],
            true
          );

        case FETCH_GET_ADS_CATEGORIES:
          return state.setIn(
            ["areFetching", "isFetchingGetGoodDealsCategories"],
            true
          );

        case FETCH_GET_HOBBIES:
          return state.setIn(["areFetching", "isFetchingGetHobbies"], true);

        case FETCH_GET_MY_CHAT_USERS:
          return state.setIn(["areFetching", "isFetchingMyChatUsers"], true);

        case FETCH_GET_CURRENT_COMMUNITY:
          return state.setIn(
            ["areFetching", "isFetchingGetCurrentCommunity"],
            true
          );

        default:
          // Do nothing
          break;
      }
      break;
    }

    case RESET_DATA:
      return initialState.set("subscribe", state.subscribe);

    case CLEAR_LOGIN:
      return state
        .set("valid", false)
        .set("validFB", false)
        .set("validApple", false)
        .set("validForgetPwd", false);

    case SET_TOOLTIP3_VISIBLE:
      return state.set("isTooltip3Visible", true);

    case SET_TOOLTIP4_VISIBLE:
      return state
        .set("isTooltip4Visible", true)
        .set("isTooltip3Visible", false);

    case SET_TOOLTIP5_VISIBLE:
      return state
        .set("isTooltip5Visible", true)
        .set("isTooltip3Visible", false)
        .set("isTooltip4Visible", false);

    case SET_TOOLTIP6_VISIBLE:
      return state
        .set("isTooltip6Visible", action.payload)
        .set("isTooltip5Visible", false);

    case SET_COMMUNITY_DETAILS:
      if (action.entities.communities) {
        const currentCommunity = Object.values(action.entities.communities)[0];
        if (currentCommunity) {
          return state.set("needRegisterKey", currentCommunity.needRegisterKey);
        }
      }
      break;

    case REGISTER_FOR_PUSH_NOTIF:
      return state.set("registerForPushNotifications", true);

    case CLEAR_REGISTER_PUSH_NOTIFICATIONS:
      return state.set("registerForPushNotifications", false);

    case NAVIGATION:
      if (action.name === "LandingScreen" && state.subscribe) {
        return state
          .set("routeName", action.name)
          .set("showLoginModal", true)
          .set("selectedCategory", 1)
          .set("subscribe", false);
      } else if (action.name === "LoginModal") {
        return state.set("routeName", action.name).set("showLoginModal", false);
      }
      return state.set("routeName", action.name);

    case SET_INITIAL_URL: {
      let match;
      if (
        (match = action.value?.match(/^(http:\/\/|https:\/\/|www.)\S+\?(\S+)$/))
      ) {
        const params = match[2];
        const { offline } = parse(params);
        if (offline) {
          return state.set("accessDenied", true);
        }
      }
      break;
    }

    case SET_GLOBAL_INFORMATIONS_FAILURE:
      if (action.payload.response && action.payload.response.status === 403) {
        if (state.routeName === "LandingScreen") {
          if (state.preparetoken) {
            return state
              .set("accessDenied", true)
              .set("token", state.preparetoken)
              .set("selectedCategory", 0)
              .set("showLoginModal", true);
          }
          return state.set("accessDenied", true);
        }
      }
      break;

    case SET_PROFILE_DETAILS: {
      const user = action.payload;
      return state
        .set("accessDenied", false)
        .set("token", null)
        .set("preparetoken", null)
        .set(
          "registerForPushNotifications",
          Boolean(
            state.fromLogin &&
              user &&
              (user.role !== ROLE_CAMPER || !user.needUpdate) &&
              !state.token
          )
        );
    }

    case CHANGE_CUSTOMER_TARGET:
      return state
        .set("selectedIndex", action.value)
        .set("scroll", action.scroll);

    case SET_EMAIL_OR_PSEUDO:
      return state
        .set("emailOrPseudo", action.value)
        .set("emailOrPseudoErrorMsg", null)
        .set("passwordErrorMsg", null)
        .set("registerkeyErrorMsg", null)
        .set("tokenErrorMsg", null);

    case SET_PASSWORD:
      return state
        .set("password", action.value)
        .set("emailOrPseudoErrorMsg", null)
        .set("passwordErrorMsg", null)
        .set("tokenErrorMsg", null);

    case SET_DEEP_LINK_TOKEN:
      if (
        state.routeName === "LoginModal" ||
        state.routeName === "LandingScreen"
      ) {
        if (state.accessDenied) {
          return state
            .set("token", action.payload)
            .set("emailOrPseudoErrorMsg", null)
            .set("passwordErrorMsg", null)
            .set("valid", false)
            .set("clearValidate", false)
            .set("selectedCategory", 0)
            .set(
              "showLoginModal",
              Boolean(state.routeName === "LandingScreen")
            );
        } else {
          return state.set("preparetoken", action.payload);
        }
      }
      break;

    case SET_REGISTER_KEY:
      return state
        .set("registerkey", action.value)
        .set("selectedCategory", 1)
        .set("emailOrPseudoErrorMsg", null)
        .set("registerkeyErrorMsg", null);

    case SET_SELECTED_CATEGORY:
      return state
        .set("selectedCategory", action.value)
        .set("emailOrPseudoErrorMsg", null)
        .set("passwordErrorMsg", null)
        .set("tokenErrorMsg", null)
        .set("registerkeyErrorMsg", null);

    case VALIDATE_INPUTS: {
      let emailOrPseudoErrorMsg;
      let registerkeyErrorMsg;

      if (!state.emailOrPseudo) {
        emailOrPseudoErrorMsg = state.selectedCategory
          ? t("login:missingemail")
          : t("login:missingemailorpseudo");
      } else if (
        (state.selectedCategory || state.emailOrPseudo.indexOf("@") >= 0) &&
        !validateEmail(state.emailOrPseudo)
      ) {
        emailOrPseudoErrorMsg = t("login:invalidemail");
      }
      // Error here
      if (
        state.needRegisterKey &&
        state.selectedCategory &&
        !state.registerkey
      ) {
        registerkeyErrorMsg = t("login:missingkey");
      }
      if (
        !emailOrPseudoErrorMsg &&
        (state.selectedCategory === 1 || state.password)
      ) {
        return state.set("clearValidate", false).set("valid", true);
      } else {
        return state
          .set("clearValidate", false)
          .set("emailOrPseudoErrorMsg", emailOrPseudoErrorMsg)
          .set(
            "passwordErrorMsg",
            state.password || state.selectedCategory === 1
              ? null
              : t("login:missingpassword")
          )
          .set("registerkeyErrorMsg", registerkeyErrorMsg);
      }
    }

    case VALIDATE_FB_INPUTS: {
      let registerkeyErrorMsg = null;
      let emailOrPseudoErrorMsg = null;

      if (
        state.needRegisterKey &&
        state.selectedCategory &&
        !state.registerkey
      ) {
        registerkeyErrorMsg = t("login:missingkey");
      } else if (
        state.selectedCategory &&
        state.emailOrPseudo &&
        !validateEmail(state.emailOrPseudo)
      ) {
        emailOrPseudoErrorMsg = t("login:invalidemail");
      }
      return state
        .set(
          "validFB",
          registerkeyErrorMsg === null && emailOrPseudoErrorMsg === null
        )
        .set("clearValidateFB", false)
        .set("emailOrPseudoErrorMsg", emailOrPseudoErrorMsg)
        .set("registerkeyErrorMsg", registerkeyErrorMsg);
    }

    case VALIDATE_GUEST_INPUT: {
      let registerkeyErrorMsg = null;

      if (
        state.needRegisterKey &&
        state.selectedCategory &&
        !state.registerkey
      ) {
        registerkeyErrorMsg = t("login:missingkey");
      }
      return state
        .set("validSignupGuest", registerkeyErrorMsg === null)
        .set("clearValidateSignupGuest", false)
        .set("registerkeyErrorMsg", registerkeyErrorMsg);
    }

    case VALIDATE_APPLE_INPUTS: {
      let registerkeyErrorMsg = null;

      if (
        state.needRegisterKey &&
        state.selectedCategory &&
        !state.registerkey
      ) {
        registerkeyErrorMsg = t("login:missingkey");
      }
      return state
        .set("validApple", registerkeyErrorMsg === null)
        .set("clearValidateApple", false)
        .set("registerkeyErrorMsg", registerkeyErrorMsg);
    }

    case VALIDATE_FORGET_PWD_INPUT: {
      const emailOrPseudoErrorMsg = state.emailOrPseudo
        ? null
        : t("login:missingemailorpseudo");
      return state
        .set("validForgetPwd", emailOrPseudoErrorMsg === null)
        .set("clearForgetPwdValidate", false)
        .set("emailOrPseudoErrorMsg", emailOrPseudoErrorMsg);
    }

    case CLEAR_VALIDATE:
      return state
        .set("valid", false)
        .set("clearValidate", true)
        .set("emailOrPseudoErrorMsg", null)
        .set("passwordErrorMsg", null)
        .set("tokenErrorMsg", null)
        .set("registerkeyErrorMsg", null);

    case CLEAR_VALIDATE_GUEST:
      return state
        .set("clearValidateSignupGuest", true)
        .set("emailOrPseudoErrorMsg", null)
        .set("passwordErrorMsg", null)
        .set("tokenErrorMsg", null)
        .set("password", null)
        .set("registerkeyErrorMsg", null)
        .set("validSignupGuest", false);

    case CLEAR_VALIDATE_FB:
      return state
        .set("clearValidateFB", true)
        .set("emailOrPseudoErrorMsg", null)
        .set("passwordErrorMsg", null)
        .set("tokenErrorMsg", null)
        .set("password", null)
        .set("registerkeyErrorMsg", null)
        .set("validFB", false)
        .set("onClick", action.onClick);

    case CLEAR_VALIDATE_APPLE:
      return state
        .set("clearValidateApple", true)
        .set("emailOrPseudoErrorMsg", null)
        .set("passwordErrorMsg", null)
        .set("tokenErrorMsg", null)
        .set("emailOrPseudo", null)
        .set("password", null)
        .set("registerkeyErrorMsg", null)
        .set("validApple", false);

    case SET_MISSING_EMAIL_ERROR:
      return state.set("emailOrPseudoErrorMsg", t("login:missingemail"));

    case CLEAR_FORGET_PWD_VALIDATE:
      return state
        .set("validForgetPwd", false)
        .set("clearForgetPwdValidate", true)
        .set("emailOrPseudoErrorMsg", null)
        .set("passwordErrorMsg", null)
        .set("tokenErrorMsg", null)
        .set("registerkeyErrorMsg", null);

    case LOGOUT:
      if (action?.value === RESET_AND_SUBSCRIBE) {
        return state.set("logout", action.value).set("subscribe", true);
      }
      return state.set("logout", action.value ? action.value : LOGOUT);

    case CLEAR_LOGOUT:
      return state.set("logout", null);

    case LOGOUT_RESULT:
      return state.set("logoutResult", action.payload);

    case DELETE_USER_RESULT:
      return state.set("deleteUserResult", action.payload);

    case LOGIN_FAILURE:
      const response = action.payload.response || "";
      if (response.status === 400) {
        return state.set(
          "emailOrPseudoErrorMsg",
          t("login:alreadyregistereduser")
        );
      } else if (response.status === 401) {
        if (response.data && response.data.message.indexOf("jwt") >= 0) {
          return state
            .set("tokenErrorMsg", t("login:tokenexpired"))
            .set("token", null)
            .set("preparetoken", null);
        } else if (state.selectedCategory === 0) {
          return state.set("passwordErrorMsg", t("login:baduserpassword"));
        } else {
          return state.set("registerkeyErrorMsg", t("login:badcampingkey"));
        }
      }

    case SET_LOGIN_WITH_FB_RESULT:
      return state
        .set("expires", action.expires)
        .set("permissions", action.permissions)
        .set("declinedPermissions", action.declinedPermissions)
        .set("profileData", action.data)
        .set(
          "emailOrPseudoErrorMsg",
          action.data &&
            state.selectedCategory &&
            !action.data.email &&
            !state.emailOrPseudo
            ? t("login:missingemail")
            : null
        );

    case API_END: {
      switch (action.payload) {
        case FETCH_LOGOUT:
          return state.set("isFetching", false);

        case FETCH_LOGIN:
        case FETCH_LOGIN_USING_TOKEN:
        case FETCH_SIGNUP:
        case FETCH_LOGIN_WITH_FB:
        case FETCH_LOGIN_WITH_APPLE:
        case FETCH_FORGET_PASSWORD:
        case FETCH_SIGNUP_WITH_FB:
        case FETCH_SIGNUP_WITH_APPLE:
        case FETCH_SIGNUP_AS_GUEST:
        case FETCH_LOGIN_AS_GUEST:
          return state.set(
            "isFetching",
            Object.keys(state.areFetching).length > 0
          );

        case FETCH_GET_GLOBAL_INFORMATIONS_FROM_LOGIN:
        case FETCH_GET_GLOBAL_INFORMATIONS:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingGetGlobalInformations"]);

        case FETCH_GET_USER:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingGetUser"]);

        case FETCH_GET_LOCATIONS:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingGetLocations"]);

        case FETCH_GET_TEMPLATES:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingGetTemplates"]);

        case FETCH_GET_TEMPLATE_DATES:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingGetTemplateDates"]);

        case FETCH_GET_TEMPLATE_TIMES:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingGetTemplateTimes"]);

        case FETCH_GET_ADS_CATEGORIES:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingGetGoodDealsCategories"]);

        case FETCH_GET_HOBBIES:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingGetHobbies"]);

        case FETCH_GET_MY_CHAT_USERS:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingMyChatUsers"]);

        case FETCH_GET_CURRENT_COMMUNITY:
          return state
            .set("isFetching", Object.keys(state.areFetching).length - 1 > 0)
            .removeIn(["areFetching", "isFetchingGetCurrentCommunity"]);

        default:
          // Do nothing
          break;
      }
      break;
    }

    default:
      return state;
  }
  return state;
}
