export const BUSINESS_NAME = "O’Camping";
export const SLOGAN = "Partageons nos vacances";
export const APP_NAME = "OCamping";
export const APP_BUNDLE_ID = "com.sa.ocamping";
export const COMMUNITY = "campsite";
export const COMMUNITIES = "campsites";
export const UUID_NAMESPACE = "879b21d3-bfe6-4c6c-9dea-f7a845c3203c";
export const PROTOCOL = "ocamping";

export const WEB_URL = "www.ocamping.fr";
export const DEEPLINK_APP_URL = "https://app.ocamping.fr";
export const DEEPLINK_URL_SCHEME = PROTOCOL + "://";
export const PWA_DEEPLINK_APP_URL = "https://staging.ocamping.digihapi.com";
export const CONTACT_MAIL = "contact@digihapi.fr";
export const URL_PREFIX = "";

export const NOTIFICATION_ANDROID_CHANNEL_NAME = APP_NAME;

export const GOOGLE_CLOUD_STORAGE_URL =
  "https://storage.googleapis.com/digihapi/" + APP_NAME;

export const CREATE_COMMUNITY_URL =
  "https://www.ocamping.fr/enregistrement-camping";
export const SENTRY_DSN =
  "https://a4410f74e1de4e88b54bc7f3d0882892@sentry.io/1806094";

export const APPLE_STORE_URL =
  "https://apps.apple.com/fr/app/ocamping/id1456533134";
export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.sa.ocamping";

export const COMMUNITY_MARKER_SUBSCRIBE =
  "https://mt.google.com/vt/icon/name=icons/spotlight/camping_v_L_8x.png&scale=2";
export const COMMUNITY_MARKER_UNSUBSCRIBE =
  "https://mt.google.com/vt/icon/name=icons/spotlight/camping_search_v_L_8x.png&scale=2";

export const TYPE_ACTIVITY = "Activity";
export const TYPE_PROPOSAL = "Proposal";
export const TYPE_TEMPLATE = "Template";

export const ROLE_GUEST = "Guest";
export const ROLE_ADVERTISER = "Advertiser";
export const ROLE_CAMPER = "Camper";
export const ROLE_CAMPER_GUEST = "Camper guest";
export const ROLE_EMPLOYEE = "Employee";
export const ROLE_EMPLOYEE_GUEST = "Employee guest";
export const ROLE_ADMINISTRATOR = "Administrator";
export const ROLE_WEBMASTER = "Webmaster";
export const ROLE_ADVERTISER_GUEST = "Advertiser guest";

export const GROUP_ID_WEATHER = "weather";
export const GROUP_ID_ACTIVITY = "activity";
export const GROUP_ID_GOODDEAL_ACTIVITY = "gooddealactivity";
export const GROUP_ID_PROPOSAL = "proposal";
export const GROUP_ID_TEMPLATE = "template";
export const GROUP_ID_PUBLISH = "publish";

export const EVENT_PING = "ping";

// Navigation routes
export const ABOUT_ROUTE = "a-propos";
export const CITIES_ROUTE = "villes";
export const COMMUNITIES_MAPS_ROUTE = "carte-campings";
export const CAMPSITES_ROUTE = "campings";
export const AD_ROUTE = "annonce";
export const MAINTENANCE_ROUTE = "maintenance";
export const CGU_ROUTE = "conditions-generales-utilisation";
export const LOCAL_LIFE_ROUTE = "vie-locale";
export const EVENT_DETAILS_ROUTE = "publication";

export const NEWS_FEED_ROUTE = "actualite";
export const MY_CONVERSATIONS_ROUTE = "mes-conversations";
export const MY_PROFILE_ROUTE = "mon-profil";
export const CAMERA_ROUTE = "appareil-photo";
export const PROFILE_ROUTE = "profil";
export const ZOOM_IMAGE_ROUTE = "zoom-image";
export const YOUTUBE_ROUTE = "youtube";

export const REGISTER_ROUTE = "enregistrement";

export const LANDING_ROUTES = new Set([
  ABOUT_ROUTE,
  CITIES_ROUTE,
  COMMUNITIES_MAPS_ROUTE,
  CAMPSITES_ROUTE,
  AD_ROUTE,
  MAINTENANCE_ROUTE,
  CGU_ROUTE,
  LOCAL_LIFE_ROUTE,
  ZOOM_IMAGE_ROUTE,
  EVENT_DETAILS_ROUTE
]);

export const AUTHENTICATED_ROUTES = new Set([
  MY_CONVERSATIONS_ROUTE,
  MY_PROFILE_ROUTE,
  PROFILE_ROUTE,
  CAMERA_ROUTE,
  ZOOM_IMAGE_ROUTE,
  YOUTUBE_ROUTE,
  LOCAL_LIFE_ROUTE,
  ABOUT_ROUTE,
  MAINTENANCE_ROUTE
]);

export const REGISTER_ROUTES = new Set([REGISTER_ROUTE]);
export const ALL_ROUTES = new Set([
  ...LANDING_ROUTES,
  ...AUTHENTICATED_ROUTES,
  ...REGISTER_ROUTES
]);
