import {
  AntDesign,
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons
} from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/core";
import React, {
  createRef,
  Fragment,
  memo,
  useEffect,
  useRef,
  useState
} from "react";
import {
  ActivityIndicator,
  Image,
  LayoutAnimation,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Avatar, Button, Tile } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import { getYoutubeMeta } from "react-native-youtube-iframe";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchGetAdDetails } from "../actions/ad";
import {
  fetchDeleteLiveEvent,
  fetchFlagInappropriateContent,
  fetchInterested,
  fetchUninterested
} from "../actions/live";
import { SET_DROP_DOWN_ALERT_WARN, SHARE_POST } from "../actions/types";
import { getImageUri } from "../assets/Images";
import ActionButton from "../components/actionButton/ActionButton";
import ConfirmationModal from "../components/ConfirmationModal";
import {
  GROUP_ID_ACTIVITY,
  GROUP_ID_GOODDEAL_ACTIVITY,
  GROUP_ID_PROPOSAL,
  GROUP_ID_PUBLISH,
  GROUP_ID_TEMPLATE,
  GROUP_ID_WEATHER,
  ROLE_GUEST,
  ROLE_WEBMASTER
} from "../config/constants";
import { getLiveEventSelector } from "../selectors";
import { getTranslatedProperty, t } from "../services/i18n";
import commonStyles, {
  COLOR2,
  COLOR5,
  DARKGREY_COLOR,
  ORANGE_COLOR,
  SCREEN_HEIGHT,
  SCREEN_WIDTH
} from "../styles/commonStyles";
import { formatDate } from "../utils/DateUtils";
import { openBrowser } from "../utils/UrlUtil";
import LiveHeader from "./LiveHeader";

const LiveEvent = ({ item, isDetails = false, template }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const isMounted = createRef(null);

  const [fetchingDelete, setFetchingDelete] = useState(false);
  const [fetchingUpdate, setFetchingUpdate] = useState(false);
  const [fetchingFlagContent] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [videoMeta, setVideoMeta] = useState(null);

  const {
    interestedusers,
    templates,
    locations,
    equipments,
    templateDates,
    templateTimes,
    user,
    inLiveScreen,
    isFetching,
    ad
  } = useSelector(getLiveEventSelector, shallowEqual);

  const {
    event,
    groupId,
    templateId,
    goodDealId: goodDeal,
    eventId,
    _id,
    userId,
    dateAdded: date,
    interestedUsersIds
  } = item;

  if (!template && templateId) {
    template = templates[templateId];
  }

  let {
    url,
    goodDealId,
    title: eventTitle,
    photoUri,
    description: eventDescription,
    youtubeVideoId,
    picto,
    startDate
  } = (template ? template : eventId ? eventId : event) || {};

  const usersanswers = {};
  if (interestedUsersIds) {
    for (const interestedUserId of interestedUsersIds) {
      if (interestedusers[interestedUserId]) {
        usersanswers[interestedUserId] = interestedusers[interestedUserId];
      } else if (interestedUserId === user._id) {
        usersanswers[interestedUserId] = user;
      }
    }
  }

  let {
    bgWhite,
    red,
    fontBold,
    font,
    fs12,
    p10,
    ph10,
    pt10,
    mt5,
    mt10,
    shadowGrey,
    mv5,
    mb5,
    row,
    flex1,
    mb10,
    darkgrey,
    color2,
    rounded10,
    uppercase,
    opacity0,
    rowReverse,
    alignItemsCenter,
    borderBottomColor5,
    borderRightColor5,
    ml10,
    overflowHidden
  } = commonStyles;

  useEffect(() => {
    (async () => {
      if (groupId === GROUP_ID_GOODDEAL_ACTIVITY && goodDeal) {
        photoUri = goodDeal.photoUri;
      }

      if (youtubeVideoId) {
        setVideoMeta(await getYoutubeMeta(youtubeVideoId));
      }
      isMounted.current = true;

      if (photoUri) {
        getImageSize(photoUri, isDetails);
      }
    })();
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (ad && inLiveScreen) {
      const { cityUri, customUri: adUri } = ad;
      navigation.navigate("AdScreen", {
        cityUri,
        adUri
      });
    }
  }, [ad]);

  const usePrevious = value => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const usersAnswersLength = Object.keys(usersanswers).length;
  const prevUsersAnswersLength = usePrevious(usersAnswersLength);

  useEffect(() => {
    if (isDetails && photoUri && !imageHeight) {
      getImageSize(photoUri, isDetails);
    }
  }, [isDetails, photoUri, imageHeight]);

  useEffect(() => {
    if (usersAnswersLength !== prevUsersAnswersLength) {
      setFetchingUpdate(false);
    }
  }, [usersAnswersLength]);

  const getTitle = () => {
    const isInterested = Boolean(user && usersanswers[user._id]);

    let title = "";
    if (isInterested) {
      if (
        groupId === GROUP_ID_ACTIVITY ||
        groupId === GROUP_ID_PROPOSAL ||
        groupId === GROUP_ID_GOODDEAL_ACTIVITY
      ) {
        title = t("button:removeanswer");
      } else if (groupId === GROUP_ID_PUBLISH) {
        title = t("button:dislike");
      } else if (user && user.gender === "M") {
        title = t("button:notinterested_M");
      } else {
        title = t("button:notinterested_F");
      }
    } else {
      if (
        groupId === GROUP_ID_ACTIVITY ||
        groupId === GROUP_ID_PROPOSAL ||
        groupId === GROUP_ID_GOODDEAL_ACTIVITY
      ) {
        title = t("button:answerme");
      } else if (groupId === GROUP_ID_PUBLISH) {
        title = t("button:like");
      } else if (user && user.gender === "M") {
        title = t("button:interested_M");
      } else {
        title = t("button:interested_F");
      }
    }
    return title;
  };

  const onConfirmationModalClosed = () => {
    setShowConfirmationModal(false);
  };

  const flagContent = () => {
    if (eventId) {
      onConfirmationModalClosed();
      dispatch(fetchFlagInappropriateContent(eventId._id));
    }
  };

  const renderInterestedUsers = () => {
    const components = [];
    for (const interestedUser of Object.values(usersanswers)) {
      components.push(
        <TouchableOpacity
          key={interestedUser._id}
          onPress={() =>
            navigation.navigate("ProfileScreen", {
              userId: interestedUser._id
            })
          }
          disabled={!user || user._id === interestedUser._id}
        >
          <Avatar
            rounded
            size={30}
            source={
              interestedUser.photoUri
                ? { uri: interestedUser.photoUri }
                : interestedUser.gender === "F"
                ? getImageUri("avatarF")
                : getImageUri("avatar")
            }
            title={interestedUser.firstname.toUpperCase().substr(0, 2)}
            activeOpacity={0.7}
            containerStyle={ml10}
          />
        </TouchableOpacity>
      );
    }
    return components;
  };

  const renderButtons = () => {
    const isInterested = Boolean(
      usersanswers && user && usersanswers[user._id]
    );
    const isMine = Boolean(user && user._id === userId);

    if (groupId === GROUP_ID_GOODDEAL_ACTIVITY && goodDeal) {
      goodDealId = goodDeal._id;
    }

    // construct event to share data
    const eventObj = {
      isEvent: Boolean(event),
      eventId: _id,
      date,
      title: eventTitle,
      photoUri,
      description: eventDescription,
      youtubeVideoId
    };

    let componentButton = null;
    switch (groupId) {
      case GROUP_ID_WEATHER:
        componentButton = (
          <Fragment>
            <ActionButton
              buttonColor={COLOR5}
              renderIcon={() => (
                <MaterialCommunityIcons
                  name="dots-horizontal"
                  type="material-community"
                  size={40}
                  color="white"
                />
              )}
              verticalOrientation="down"
              size={44}
              offsetX={18}
              offsetY={18}
              degrees={90}
              spacing={10}
            >
              <ActionButton.Item
                buttonColor={COLOR2}
                title={t("button:forecast")}
                onPress={() => navigation.navigate("MeteoTab")}
                textStyle={font}
              >
                <MaterialCommunityIcons
                  name="weather-partly-cloudy"
                  size={34}
                  color="white"
                />
              </ActionButton.Item>
            </ActionButton>
          </Fragment>
        );
        break;

      case GROUP_ID_PUBLISH:
        componentButton = (
          <Fragment>
            <ActionButton
              buttonColor={COLOR5}
              renderIcon={() => (
                <MaterialCommunityIcons
                  name="dots-horizontal"
                  size={40}
                  color="white"
                />
              )}
              verticalOrientation="down"
              size={44}
              offsetX={18}
              offsetY={18}
              degrees={90}
              spacing={10}
              // autoInactive={false}
            >
              {Boolean(url) && url.trim().length > 0 && (
                <ActionButton.Item
                  buttonColor={COLOR2}
                  title={t("button:more")}
                  onPress={() => openBrowser(dispatch, url)}
                  textStyle={font}
                >
                  <MaterialCommunityIcons name="web" size={34} color="white" />
                </ActionButton.Item>
              )}
              {Boolean(goodDealId) && (
                <ActionButton.Item
                  buttonColor={COLOR2}
                  title={t("button:more")}
                  onPress={() =>
                    dispatch(fetchGetAdDetails({ id: goodDealId }))
                  }
                  disabled={isFetching}
                  textStyle={font}
                  textHeight={24}
                >
                  {isFetching ? (
                    <ActivityIndicator size="small" color="white" />
                  ) : (
                    <MaterialCommunityIcons
                      name="dots-horizontal"
                      type="material-community"
                      size={34}
                      color="white"
                    />
                  )}
                </ActionButton.Item>
              )}
              <ActionButton.Item
                buttonColor={COLOR2}
                title={t("button:share")}
                onPress={() =>
                  dispatch({
                    type: SHARE_POST,
                    event: eventObj
                  })
                }
                textStyle={font}
                textHeight={24}
              >
                <FontAwesome name="share-alt" size={30} color="white" />
              </ActionButton.Item>
              {isMine || user?.role === ROLE_WEBMASTER ? (
                <ActionButton.Item
                  buttonColor="red"
                  title={t("button:delete")}
                  onPress={() => {
                    isMounted.current && setFetchingDelete(true);
                    dispatch(fetchDeleteLiveEvent(_id));
                    if (isDetails) {
                      navigation.goBack();
                    }
                  }}
                  textStyle={font}
                  textHeight={24}
                  disabled={fetchingDelete}
                >
                  {fetchingDelete ? (
                    <ActivityIndicator size="small" color="white" />
                  ) : (
                    <MaterialCommunityIcons
                      name={"delete-forever"}
                      size={34}
                      color="white"
                    />
                  )}
                </ActionButton.Item>
              ) : (
                Boolean(isDetails && user) &&
                user.role !== ROLE_GUEST && (
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={t("button:flagcontent")}
                    onPress={() => {
                      LayoutAnimation.easeInEaseOut();
                      setShowConfirmationModal(true);
                    }}
                    textStyle={font}
                    textHeight={24}
                  >
                    <MaterialCommunityIcons
                      name="alert-decagram"
                      size={34}
                      color="white"
                    />
                  </ActionButton.Item>
                )
              )}
            </ActionButton>
          </Fragment>
        );
        break;

      case GROUP_ID_GOODDEAL_ACTIVITY:
      case GROUP_ID_ACTIVITY:
      case GROUP_ID_PROPOSAL:
      case GROUP_ID_TEMPLATE:
        {
          componentButton = (
            <Fragment>
              <ActionButton
                buttonColor={COLOR5}
                renderIcon={() => (
                  <MaterialCommunityIcons
                    name="dots-horizontal"
                    size={40}
                    color="white"
                  />
                )}
                verticalOrientation="down"
                size={44}
                offsetX={18}
                offsetY={18}
                degrees={90}
                spacing={10}
                autoInactive={false}
              >
                {Boolean(goodDealId) && (
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={t("button:more")}
                    disabled={isFetching}
                    onPress={() =>
                      dispatch(fetchGetAdDetails({ id: goodDealId }))
                    }
                    textStyle={font}
                    textHeight={24}
                  >
                    {isFetching ? (
                      <ActivityIndicator size="small" color="white" />
                    ) : (
                      <MaterialCommunityIcons
                        name="dots-horizontal"
                        type="material-community"
                        size={34}
                        color="white"
                      />
                    )}
                  </ActionButton.Item>
                )}
                {!isMine && (
                  <ActionButton.Item
                    buttonColor={COLOR2}
                    title={getTitle()}
                    onPress={() => {
                      if (user && user.role !== ROLE_GUEST) {
                        isMounted.current && setFetchingUpdate(true);
                        dispatch(
                          isInterested
                            ? fetchUninterested(_id)
                            : fetchInterested(_id)
                        );
                      } else {
                        dispatch({
                          type: SET_DROP_DOWN_ALERT_WARN,
                          warn: "guestforbidden"
                        });
                      }
                    }}
                    textStyle={font}
                    textHeight={24}
                  >
                    {fetchingUpdate ? (
                      <ActivityIndicator size="small" color="white" />
                    ) : (
                      <MaterialCommunityIcons
                        name={isInterested ? "star-outline" : "star-face"}
                        size={34}
                        color="white"
                      />
                    )}
                  </ActionButton.Item>
                )}
                {isMine ||
                  (!isMine && isInterested && (
                    <ActionButton.Item
                      buttonColor={COLOR2}
                      title={t("button:groupchat")}
                      onPress={() => {
                        if (user && user.role !== ROLE_GUEST) {
                          navigation.navigate("ChatGrpScreen", {
                            eventId: _id
                          });
                        } else {
                          dispatch({
                            type: SET_DROP_DOWN_ALERT_WARN,
                            warn: "guestforbidden"
                          });
                        }
                      }}
                      textStyle={font}
                      textHeight={24}
                    >
                      <Ionicons
                        name={"ios-chatbubbles"}
                        size={34}
                        color="white"
                      />
                    </ActionButton.Item>
                  ))}
                {(isMine || user?.role === ROLE_WEBMASTER) && (
                  <ActionButton.Item
                    buttonColor="red"
                    title={t("button:delete")}
                    onPress={() => {
                      isMounted.current && setFetchingDelete(true);
                      dispatch(fetchDeleteLiveEvent(_id));
                      if (isDetails) {
                        navigation.goBack();
                      }
                    }}
                    textStyle={font}
                    textHeight={24}
                    disabled={fetchingDelete}
                  >
                    {fetchingDelete ? (
                      <ActivityIndicator size="small" color="white" />
                    ) : (
                      <MaterialCommunityIcons
                        name={"delete-forever"}
                        size={34}
                        color="white"
                      />
                    )}
                  </ActionButton.Item>
                )}
              </ActionButton>
            </Fragment>
          );
        }
        break;
    }
    return componentButton;
  };

  const getImageSize = (photoUri, isDetails) => {
    Image.getSize(
      photoUri,
      (width, height) => {
        const proportion = height / width;
        const imageWidth = SCREEN_WIDTH - 40;
        let imageHeight = Math.ceil(imageWidth * proportion);
        if (!isDetails) {
          if (proportion > 1) {
            if (imageHeight > SCREEN_HEIGHT / 2) {
              imageHeight = SCREEN_HEIGHT / 2;
            }
          } else if (imageHeight > SCREEN_HEIGHT / 3) {
            imageHeight = SCREEN_HEIGHT / 3;
          }
        }
        if (isMounted.current) {
          setImageWidth(imageWidth);
          setImageHeight(imageHeight);
        }
      },
      // In case of error, we display image with image height
      () => isMounted.current && setImageHeight(SCREEN_HEIGHT / 3)
    );
  };

  let when,
    where,
    title,
    description,
    postDescription = null;
  const isInterested = Boolean(usersanswers && user && usersanswers[user._id]);
  const fromId = user && user._id === userId ? "" : userId;

  if (event) {
    const { dateId, timeId, locationId, equipmentId, choiceId } = event;
    when = dateId
      ? getTranslatedProperty(templateDates[dateId], "name") +
        (timeId
          ? " - " + getTranslatedProperty(templateTimes[timeId], "name")
          : "")
      : null;
    where = locationId
      ? getTranslatedProperty(locations[locationId], "name")
      : equipmentId
      ? getTranslatedProperty(equipments[equipmentId], "name")
      : null;
    if (choiceId) {
      if (groupId === GROUP_ID_GOODDEAL_ACTIVITY && goodDeal?.choices) {
        description = getTranslatedProperty(
          goodDeal.choices[choiceId],
          "description"
        );
        postDescription = getTranslatedProperty(
          goodDeal.choices[choiceId],
          "postDescription"
        );
      } else if (template.choices) {
        description = getTranslatedProperty(
          template.choices[choiceId],
          "description"
        );
        postDescription = getTranslatedProperty(
          template.choices[choiceId],
          "postDescription"
        );
      }
    } else {
      if (groupId === GROUP_ID_GOODDEAL_ACTIVITY) {
        description = goodDeal
          ? getTranslatedProperty(goodDeal, "activityDescription")
          : getTranslatedProperty(event, "activityDescription");
        postDescription = goodDeal
          ? getTranslatedProperty(goodDeal, "activityPostDescription")
          : getTranslatedProperty(event, "activityPostDescription");
      } else {
        description = template
          ? getTranslatedProperty(template, "description")
          : getTranslatedProperty(event, "description");
        postDescription = template
          ? getTranslatedProperty(template, "postDescription")
          : getTranslatedProperty(event, "postDescription");
      }
    }
  }
  if (eventId) {
    title = getTranslatedProperty(eventId, "title");
    description = getTranslatedProperty(eventId, "description");
  }

  if (groupId === GROUP_ID_GOODDEAL_ACTIVITY && goodDeal) {
    photoUri = goodDeal.photoUri;
  }

  const displayPhotoUri = Boolean(
    groupId !== GROUP_ID_ACTIVITY && groupId !== GROUP_ID_PROPOSAL && photoUri
  );
  return (
    <View style={[bgWhite, shadowGrey, _id ? mv5 : mb5]}>
      <View style={[ph10, pt10, _id ? borderBottomColor5 : null]}>
        {_id && <LiveHeader item={item} />}
        <ConfirmationModal
          description={t("profile:confirmflag")}
          onClosed={onConfirmationModalClosed}
          title={t("menu:confirmation")}
          showConfirmationModal={showConfirmationModal}
          onConfirmation={flagContent}
          isFetching={fetchingFlagContent}
          buttonConfirm={t("button:flag")}
          buttonColor={ORANGE_COLOR}
        />
        {isDetails ? (
          <View
            style={[p10, groupId === GROUP_ID_PUBLISH && { minHeight: 250 }]}
          >
            {Boolean(title) && (
              <Text style={[fontBold, uppercase]}>{title}</Text>
            )}

            {groupId === GROUP_ID_ACTIVITY || groupId === GROUP_ID_PROPOSAL ? (
              <View style={[flex1, row, mb10]}>
                <View style={styles.buttonActivity}>
                  <Image
                    style={[
                      {
                        width: 50,
                        height: 50,
                        position: "absolute",
                        bottom: 5,
                        tintColor: DARKGREY_COLOR
                      }
                    ]}
                    source={getImageUri(picto)}
                  />
                  <Image
                    style={[
                      {
                        width: 50,
                        height: 50,
                        position: "absolute",
                        bottom: 5
                      },
                      opacity0
                    ]}
                    source={getImageUri(picto)}
                  />
                </View>
                <Text style={[flex1, mt10, font]}>{description}</Text>
              </View>
            ) : (
              <Text style={[font, mb10]}>{description}</Text>
            )}
            {displayPhotoUri && (
              <Avatar
                containerStyle={[
                  { height: imageHeight, width: imageWidth },
                  rounded10,
                  mb10
                ]}
                renderPlaceholderContent={
                  <Image
                    style={[
                      { height: imageHeight, width: imageWidth },
                      rounded10
                    ]}
                    source={getImageUri("placeholder")}
                  />
                }
                source={{
                  uri: photoUri
                }}
                overlayContainerStyle={[rounded10]}
                onPress={() => {
                  navigation.navigate("ImageZoomScreen", {
                    uri: photoUri
                  });
                }}
              />
            )}
            {videoMeta && (
              <Tile
                activeOpacity={0.5}
                containerStyle={[
                  {
                    height: ((SCREEN_WIDTH - 40) * 9) / 16,
                    width: SCREEN_WIDTH - 40
                  },
                  rounded10,
                  overflowHidden
                ]}
                imageSrc={{ uri: videoMeta.thumbnail_url }}
                imageContainerStyle={[
                  rounded10,
                  {
                    height: ((SCREEN_WIDTH - 40) * 9) / 16,
                    width: SCREEN_WIDTH - 40
                  }
                ]}
                icon={{
                  name: "play-circle",
                  type: "font-awesome",
                  size: 50,
                  color: COLOR2
                }}
                onPress={() => {
                  navigation.navigate("YoutubeScreen", {
                    videoId: youtubeVideoId
                  });
                }}
                featured
              />
            )}
            {Boolean(postDescription) && (
              <Text style={[font, mb10]}>{postDescription}</Text>
            )}
            {Boolean(where) && (
              <View style={[row]}>
                <View style={[flex1]} />
                <Text style={[font, darkgrey]}>{t("live:where")}</Text>
                <Text style={[fontBold]}>{where}</Text>
              </View>
            )}
            {Boolean(startDate || when) && (
              <View style={[row]}>
                <View style={[flex1]} />
                <Text style={[font, darkgrey]}>{t("live:when")}</Text>
                <Text style={[fontBold]}>
                  {startDate ? `${formatDate(startDate)}` : when}
                </Text>
              </View>
            )}
            {Boolean(usersanswers && Object.values(usersanswers).length) && (
              <ScrollView
                nestedScrollEnabled={true}
                horizontal={true}
                style={[flex1, mt5, rowReverse]}
                contentContainerStyle={alignItemsCenter}
              >
                <Text style={[font, darkgrey]}>
                  {groupId === GROUP_ID_PUBLISH
                    ? "👍 :"
                    : Object.values(usersanswers).length > 1
                    ? t("live:answers")
                    : t("live:answer")}
                </Text>
                {renderInterestedUsers()}
              </ScrollView>
            )}
          </View>
        ) : (
          <View style={[p10]}>
            {Boolean(title) && (
              <Text style={[fontBold, uppercase]}>{title}</Text>
            )}
            {Boolean(
              groupId === GROUP_ID_ACTIVITY || groupId === GROUP_ID_PROPOSAL
            ) ? (
              <View style={[row, mb10]}>
                <TouchableOpacity
                  style={styles.buttonActivity}
                  onPress={() =>
                    _id &&
                    navigation.navigate("EventInterestedUsersScreen", {
                      id: _id
                    })
                  }
                  disabled={!Boolean(_id)}
                >
                  <Image
                    style={{
                      width: 50,
                      height: 50,
                      position: "absolute",
                      bottom: 5,
                      tintColor: DARKGREY_COLOR
                    }}
                    source={getImageUri(picto)}
                  />
                  <Image
                    style={{
                      width: 50,
                      height: 50,
                      bottom: 5,
                      position: "absolute",
                      opacity: 0
                    }}
                    source={getImageUri(picto)}
                  />
                </TouchableOpacity>
                <Text style={[flex1, mt10, font]}>{description}</Text>
              </View>
            ) : (
              <TouchableOpacity
                onPress={() => {
                  _id &&
                    navigation.navigate("EventInterestedUsersScreen", {
                      id: _id
                    });
                }}
              >
                <Text style={[font, mb10]} numberOfLines={title ? 2 : 3}>
                  {description}
                </Text>
              </TouchableOpacity>
            )}
            {displayPhotoUri && (
              <Avatar
                containerStyle={[
                  {
                    height: imageHeight,
                    width: imageWidth
                  },
                  rounded10,
                  mb10
                ]}
                renderPlaceholderContent={
                  <Image
                    style={[
                      {
                        height: imageHeight,
                        width: imageWidth
                      },
                      rounded10
                    ]}
                    source={getImageUri("placeholder")}
                  />
                }
                source={{
                  uri: photoUri
                }}
                overlayContainerStyle={[rounded10]}
                onPress={() => {
                  _id && Boolean(user && user.role && !fromId)
                    ? navigation.navigate("EventInterestedUsersScreen", {
                        id: _id
                      })
                    : goodDeal
                    ? dispatch(fetchGetAdDetails({ id: goodDealId }))
                    : navigation.navigate("ImageZoomScreen", {
                        uri: photoUri
                      });
                }}
              />
            )}
            {videoMeta && (
              <Tile
                activeOpacity={0.5}
                containerStyle={[
                  {
                    height: ((SCREEN_WIDTH - 40) * 9) / 16,
                    width: SCREEN_WIDTH - 40
                  },
                  rounded10,
                  overflowHidden
                ]}
                imageSrc={{ uri: videoMeta.thumbnail_url }}
                imageContainerStyle={[
                  rounded10,
                  {
                    height: ((SCREEN_WIDTH - 40) * 9) / 16,
                    width: SCREEN_WIDTH - 40
                  }
                ]}
                icon={{
                  name: "play-circle",
                  type: "font-awesome",
                  size: 50,
                  color: COLOR2
                }}
                onPress={() => {
                  Boolean(user && user.role && !fromId)
                    ? navigation.navigate("EventInterestedUsersScreen", {
                        id: _id
                      })
                    : navigation.navigate("YoutubeScreen", {
                        videoId: youtubeVideoId
                      });
                }}
                featured
              />
            )}
            {Boolean(postDescription) && (
              <Text style={[font, mb10]}>{postDescription}</Text>
            )}
            {Boolean(where) && (
              <View style={[row]}>
                <View style={[flex1]} />
                <Text style={[font, darkgrey]}>{t("live:where")}</Text>
                <Text style={[fontBold, darkgrey]}>{where}</Text>
              </View>
            )}
            {Boolean(startDate || when) && (
              <View style={[row]}>
                <View style={[flex1]} />
                <Text style={[font, darkgrey]}>{t("live:when")}</Text>
                <Text style={[fontBold, darkgrey]}>
                  {startDate ? `${formatDate(startDate)}` : when}
                </Text>
              </View>
            )}
            {Boolean(usersanswers && Object.values(usersanswers).length) && (
              <View style={[flex1, mt5, alignItemsCenter, rowReverse]}>
                {renderInterestedUsers()}
                <Text style={[font, darkgrey]}>
                  {groupId === GROUP_ID_PUBLISH
                    ? "👍 :"
                    : Object.values(usersanswers).length > 1
                    ? t("live:answers")
                    : t("live:answer")}
                </Text>
              </View>
            )}
          </View>
        )}
        {_id && renderButtons()}
      </View>
      {Boolean(_id) && (
        <View style={[flex1, row]}>
          <View style={[flex1, borderRightColor5]}>
            {Boolean(groupId !== GROUP_ID_PUBLISH) && (
              <Button
                type="clear"
                icon={
                  <Ionicons name={"ios-chatbubbles"} size={15} color={COLOR2} />
                }
                titleStyle={[fontBold, color2, fs12, { paddingLeft: 5 }]}
                containerStyle={[{ alignSelf: "flex-start" }]}
                title={t("button:groupchat")}
                onPress={() => {
                  if (user && user.role !== ROLE_GUEST) {
                    navigation.navigate("ChatGrpScreen", {
                      eventId: _id
                    });
                  } else {
                    dispatch({
                      type: SET_DROP_DOWN_ALERT_WARN,
                      warn: "guestforbidden"
                    });
                  }
                }}
              />
            )}
          </View>
          <View style={[flex1]}>
            {Boolean(fromId) && (
              <Button
                type="clear"
                titleStyle={[
                  fontBold,
                  isInterested ? red : color2,
                  fs12,
                  { paddingLeft: 5 }
                ]}
                icon={
                  groupId === GROUP_ID_PUBLISH ? (
                    <AntDesign
                      name={isInterested ? "dislike1" : "like1"}
                      size={15}
                      color={isInterested ? "red" : COLOR2}
                    />
                  ) : (
                    <MaterialCommunityIcons
                      name={isInterested ? "star-outline" : "star-face"}
                      size={18}
                      color={isInterested ? "red" : COLOR2}
                    />
                  )
                }
                containerStyle={[{ alignSelf: "flex-end", paddingRight: 5 }]}
                title={
                  isInterested
                    ? groupId === GROUP_ID_PUBLISH
                      ? t("button:dislike")
                      : t("button:removeanswer")
                    : groupId === GROUP_ID_PUBLISH
                    ? t("button:like")
                    : t("button:answerme")
                }
                loading={fetchingUpdate}
                onPress={() => {
                  if (user && user.role !== ROLE_GUEST) {
                    isMounted.current && setFetchingUpdate(true);
                    dispatch(
                      isInterested
                        ? fetchUninterested(_id)
                        : fetchInterested(_id)
                    );
                  } else {
                    dispatch({
                      type: SET_DROP_DOWN_ALERT_WARN,
                      warn: "guestforbidden"
                    });
                  }
                }}
              />
            )}
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  buttonActivity: {
    height: 60,
    width: 60,
    borderColor: DARKGREY_COLOR,
    borderRadius: 3,
    backgroundColor: "white",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "space-evenly",
    margin: 5
  }
});

const MemoizedLiveEvent = memo(LiveEvent);
export default MemoizedLiveEvent;
